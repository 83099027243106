import { Select } from "antd";
const { Option } = Select;

/** 数据集生成列表Option */
const getList = () => {
};

/**
 * 获取通用列表
 * @param rows 数据集
 * @param options 选项
 * @returns Option 
 */
getList.general = (rows: any, options?: {
    /** Option.item.id，默认：'id' */
    itemId?: string,
    /** Option.item.name，默认：'name' */
    itemName?: string,
    /** Option.item.name是否包含id */
    isItemNameIncludeId?: boolean,
    /** 设置itemName的函数，setItemNameFun(item: any):<name: string>，必需返加itemName的内容，如存在，itemName参数无效 */
    setItemNameFun?: Function,
    /** 没有id键的数组 */
    noId?: boolean,
    /** 包括：无(NULL)，默认：false */
    includeNull?: boolean
}) => {
    let { itemId, itemName, isItemNameIncludeId, setItemNameFun, noId, includeNull } = options || {};
    /** 返回 */
    let res: any;
    if (typeof rows !== "undefined") {
        if (typeof itemId === 'undefined') {
            itemId = 'id';
        }
        if (typeof itemName === 'undefined') {
            itemName = 'name';
        }
        if (Array.isArray(rows) === false) {
            rows = [rows];
        }
        if (includeNull === true) {
            rows = rows.concat({
                [itemId]: null,
                [itemName]: '无（null）'
            });
        }
        res = rows.map((item: any, key: number) => {
            /** 当前id */
            let currentId = noId === true ? item : item[itemId as string];
            /** 当前name */
            let currentName;
            if (setItemNameFun) {
                const obj = setItemNameFun(item);
                if (typeof obj !== 'undefined') {
                    const { id, name } = obj;
                    currentId = id;
                    currentName = name;
                }
            } else {
                currentName = noId === true ? item : item[itemName as string];
            }
            if (currentId === null) {
                currentName = '无（null）';
            }
            if (isItemNameIncludeId === true) {
                currentName = `${currentId} - [${currentName}]`;
            }
            return <Option value={currentId} key={key}>{currentName}</Option>
        })
    }
    return res;
};

export default getList;