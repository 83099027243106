import { Form, Input, Row, Col, Switch, InputNumber, DatePicker, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const [giftMemberCardTypeData, setGiftMemberCardTypeData] = useState<any>();
    const [wxUserData, setWxUserData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    const unionId = res.data.unionId;
                    fetchApi.GET(defaultService, {
                        apiId: 'webWxUser',
                        apiVariable: unionId
                    }).then(res => {
                        if (res.success) {
                            setWxUserData([res.data]);
                        }
                    })
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'webGiftMemberCardType',
                    apiExtend: 'showList',
                }).then(res => {
                    if (res.success) {
                        setGiftMemberCardTypeData(res.data);
                    }
                })
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webGiftMemberCard',
        dateColumnName: ['activationDate', 'expiryDate'],
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '礼品卡'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="类型"
                                name="giftMemberCardTypeId"
                                rules={[{ required: true, message: '类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(giftMemberCardTypeData, 'typeName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="原始金额"
                                name="originalAmount">
                                <InputNumber
                                    disabled={data.type === 'NEW' ? false : true}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="余额"
                                name="balance">
                                <InputNumber
                                    disabled={data.type === 'NEW' ? true : false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={13}>
                            <Form.Item
                                label="微信"
                                name="unionId">
                                <Select
                                    showSearch
                                    placeholder="微信"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('webWxUser', setWxUserData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(wxUserData, 'nickName', 'unionId')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="密码"
                                name="password">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="实体卡"
                                name="isPhysicalCard"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="激活"
                                name="isActivation"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="激活日期"
                                name="activationDate">
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="失效日期"
                                name="expiryDate">
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebEdit;