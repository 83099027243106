import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal from '../../system/WebEditUniversal';
import fetchApi from '../../../utils/api/fetchApi';
import httpUrl from '../../../config/httpUrl';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const ShopsEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const [wxUserData, setWxUserData] = useState<any>();
    const [userData, setUserData] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            fetchApi.GET(await httpUrl.general('webWxUser', undefined, 'ShowList')).then(res => {
                if (res.success) {
                    setWxUserData(res.data);
                    console.log('微信用户资料');
                    console.log(res.data);
                    
                    
                }
            });
            fetchApi.GET(await httpUrl.general('user', undefined, 'ShowList')).then(res => {
                if (res.success) {
                    setUserData(res.data);
                }
            });
        };
        fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const { Option } = Select;
    const getWxUserList = (datas: any) => {
        if (typeof datas !== "undefined") {
            return (datas.map((item: any, key: number) => {
                return <Option value={item.unionId} key={key}>{item.nickName}</Option>
            })
            )
        }
    };
    const getUserList = (datas: any) => {
        if (typeof datas !== "undefined") {
            return (datas.map((item: any, key: number) => {
                return <Option value={item.id} key={key}>{item.userName}</Option>
            })
            )
        }
    };

    let extendProps: any = {
        ...props,
        form,
        apiName: 'deliveryAddress',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '收货地址'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="微信用户"
                                name="unionId">
                                <Select
                                    showSearch
                                    placeholder="微信用户"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getWxUserList(wxUserData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="用户"
                                name="userId">
                                <Select
                                    showSearch
                                    placeholder="用户"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getUserList(userData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="收货人"
                                name="deliveryName"
                                rules={[{ required: true, message: '收货人!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="收货电话"
                                name="phone"
                                rules={[{ required: true, message: '收货电话!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="省市区"
                                name="region"
                                rules={[{ required: true, message: '省市区!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="详细地址"
                                name="detail"
                                rules={[{ required: true, message: '详细地址!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="邮编"
                                name="postcode">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="默认"
                                name="isDefault"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default ShopsEdit;