import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const { TextArea } = Input
    const [userData, setUserData] = useState<any>();
    const [brandData, setBrandData] = useState<any>();
    /** 后端数据wmService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;

        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'user',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setUserData(res.data);
                    }
                });
            fetchApi.GET(defaultService,
                {
                    apiId: 'brand',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setBrandData(res.data);
                    }
                });
        };
        fetchData();
    }, []);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'configNodes',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '配置列表'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>

                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id"
                                rules={[{ required: true, message: '请输入id!' }]}>
                                <Input disabled={data.type === 'NEW' ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="配置名称"
                                name="configName"
                                rules={[{ required: true, message: '请输入配置名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="品牌"
                                name="brandId">
                                <Select
                                    showSearch
                                    placeholder="选择品牌"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(brandData, 'brandName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="用户"
                                name="userId">
                                <Select
                                    showSearch
                                    placeholder="选择用户"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(userData, 'userName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="节点内容"
                                name="nodes">
                                <TextArea rows={10} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;