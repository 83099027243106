import { Form, Input } from 'antd';
import React, { useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const LicenseGroupEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'licenseGroup',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '权限组'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="权限组名"
                        name="licenseGroupName"
                        rules={[{ required: true, message: '请输入权限组名!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="图标"
                        name="icon">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="licenseGroupSort">
                        <Input type={"number"} />
                    </Form.Item>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default LicenseGroupEdit;