import { useEffect, useRef, useState } from 'react';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';
import { Button, Switch, Tooltip } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

const WebComponent = (props: any) => {
    const apiId = 'webWxOpenId';
    const { closeModal, data } = props;
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 默认后台service */
    const [defaultService, setDefaultService] = useState<any>();
    /** 刷新 */
    const [initData, setInitData] = useState<any>();
    useEffect(() => {
        if (ref.current) {
            setDefaultService(ref.current.defaultService);
            setInitData(() => ref.current.initData);
        }
        // const fetchData = async () => {
        // };
        // fetchData();
    }, []); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: 'openId',
            dataIndex: 'openId',
            render: (text: any) => getColumnsheadId({
                title: 'openId',
                dataIndex: 'openId',
                pos: 'suffix',
                returnType: 'Object',
                text
            })
        },
        {
            title: '微信帐号',
            dataIndex: 'wxAccount_Name',
        },
        {
            title: 'unionId',
            dataIndex: 'unionId',
            render: (text: string) => getColumnsheadId({
                title: 'openId',
                dataIndex: 'openId',
                pos: 'suffix',
                returnType: 'Object',
                text
            })
        }
    ];

    /**
     * 表头
     */
    const columns = columnsHead;

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        primaryKeyName: 'openId',
        titleName: 'wxOpenId',
        apiId,
        columns,
        showStates: true,
        showQuery: false,
        showAdd: false,
        showEdit: false,
        sendPageData: {
            ...data
        }
    };

    return (
        <>
            <AntDraggableModal
                title={'wxOpenId'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={closeModal}
                closable={false}
                maskClosable={false}
                width={1080} >
                <WebUniversal props={extendProps} ref={ref} />
            </AntDraggableModal>
        </>
    )
}

export default WebComponent;