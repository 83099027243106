import { useEffect, useRef, useState } from 'react';
import RoleEdit from './RoleEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { Space, Button, Tooltip } from 'antd';
import RoleOrUserLicense from './RoleOrUserLicense';
import { columnsheadId } from '../../universal/columnshead';
import fetchApi from '../../../utils/api/fetchApi';
import RoleOrUserLicenseEx from './RoleOrUserLicenseEx'

const Role = (props: any) => {

    const ref = useRef<any>(undefined);
    const [modalListopen, setModalListopen] = useState(false);
    const [roleInfo, setRoleInfo] = useState<any>();
    const [modalRoleLicenseEx, setModalRoleLicenseEx] = useState(false);
    /** 默认后台service */
    let defaultService: any;
    /** 刷新 */
    let initData: any;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleLicense = (record: any) => {
        setRoleInfo({
            roleOrUserId: record.id,
            itemName: record.roleName
        });
        setModalListopen(true);
    }
    /**
     * 复制角色
     * @param roleId 
     */
    const handleCopyRole = (roleId: string) => {
        fetchApi.POST(defaultService, {
            apiId: 'role',
            apiExtend: 'copy',
            apiVariable: roleId
        }).then(res => {
            initData();
        })
    };
    //子组件回调函数，关闭modal
    const onCloseModalList = (state: boolean) => {
        setModalListopen(false)
    };

    /** 角色权限（特殊）扩展 */
    const handleRoleLicenseExpand = (record: any) => {
        setRoleInfo({
            roleOrUserId: record.id,
            itemName: record.roleName
        });
        setModalRoleLicenseEx(true)
    };
    /** 子组件回调函数，关闭角色权根（特殊）扩展 */
    const onCloseModalRoleLicenseEx = () => {
        setModalRoleLicenseEx(false)
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '角色名称',
            dataIndex: 'roleName',
            sorter: true,
        },
        {
            title: '角色组',
            dataIndex: 'roleGroup',
            sorter: true,
            render: (text: any) => (text ? text.roleGroupName : '')
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            render: (text: any) => (
                text ? text.brandName : ''
            )
        },
        {
            title: '权限',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleLicense(record)}>权限</Button>
                </Space>
        },
        {
            title: '特殊',
            dataIndex: 'roleLicenseExpand',
            key: 'roleLicenseExpand',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={'权限（特殊）扩展'}>
                        <Button size={"small"} type="primary" onClick={() => handleRoleLicenseExpand(record)}>特殊</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: '复制',
            dataIndex: 'id',
            key: 'id',
            render: (text: any, record: any) =>
                <Tooltip placement="topLeft" title={'复制【' + record.roleName + '】角色'}> <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleCopyRole(text)}>复制</Button>
                </Space></Tooltip>
        }
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '角色',
        apiId: 'role',
        columns,
        showStates: true,
        pagination: true,
        EditComponent: RoleEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalListopen ? (<RoleOrUserLicense
                closeModal={onCloseModalList}
                info={roleInfo}
                useType={'roleLicense'} />)
                : null}
            {modalRoleLicenseEx ? (<RoleOrUserLicenseEx
                closeModal={onCloseModalRoleLicenseEx}
                useType={'roleLicense'}
                info={roleInfo}
            ></RoleOrUserLicenseEx>) : null}
        </>
    )
}

export default Role;