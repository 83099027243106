import { useEffect, useRef, useState } from 'react';
import MembershipEdit from './MembershipEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import fetchApi from '../../utils/api/fetchApi';
import { columnsheadId } from '../universal/columnshead';
import { Tooltip } from 'antd';

const WebComponent = (props: any) => {
    const ref = useRef<any>(undefined);
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        //同步获取数据 async - await
        // const fetchData = async () => {
        // }
        // fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '姓名',
            dataIndex: 'membershipName',
            sorter: true,
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '会员',
        apiId: 'membership',
        columns,
        showStates: true,
        EditComponent: MembershipEdit,
        serviceId: 'membership'
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebComponent;
