import { useEffect, useRef, useState } from 'react';
import WebProductSpecEdit from './WebProductSpecEdit';
import WebUniversal, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import WebProductSpecValue from './WebProductSpecValue';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Button, Space, Tooltip } from 'antd';
import numberHandle from '../../../utils/baseLib/numberHandle';

//组件 WebProductSpec 
const WebProductSpec = (props: any) => {
    const ref = useRef<any>(undefined);
    const { data, closeModal } = props;
    const apiId = 'webProductSpec';
    //规格内容窗口
    const [modalProductSpecValueVisible,
        setModalProductSpecValueVisible] = useState(false);
    //规格内容数据
    const [productSpecValueData, setProductSpecValueData] = useState<any>();
    /** 刷新数据 */
    let initData: any;
    //刷新变量
    const [refresh, setRefresh] = useState(0);


    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        if (refresh !== 0) {
            const currentData: initDataType = {
                apiId
            }
            initData(currentData);
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]); //初始化数据


    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
    }
    //打开规格内容
    const hanldProductSpecValue = (e: any) => {
        sendData.id = e;
        setProductSpecValueData(sendData);
        setModalProductSpecValueVisible(true);
    };
    //关闭产品规格，子组件回调函数
    const onCloseModalProductSpecValueVisible = (state: boolean) => {
        setModalProductSpecValueVisible(false);
        setRefresh(numberHandle.rangeNumber(1, 9999));
    };

    const getProductSpecValues = (rows: any) => {
        let title = '';
        let buttonText = '';
        if (typeof rows === "undefined" || rows === null) { return buttonText }
        for (var i = 0, len = rows.length; i < len; i++) {
            title = title + (title !== '' ? '，' : '') + rows[i].specValue;
            if (i === 0) {
                buttonText = rows[i].specValue
            }
        }
        return (
            <Tooltip placement="topLeft" title={title}>
                {buttonText + "..."}
            </Tooltip>
        )
    };

    //表头
    const columnsHead = [
        {
            title: '产品',
            dataIndex: 'product',
            render: (text: any) =>
                (typeof text !== "undefined" && text !== null) ?
                    <Tooltip placement="topLeft" title={text.productName}>
                        {(text.productName.length >= 8 ?
                            text.productName.substring(0, 8) + '......' :
                            text.productName)}
                    </Tooltip>
                    : ''
        },
        {
            title: '规格类别',
            dataIndex: 'productSpecType',
            render: (text: any) =>
                (typeof text !== "undefined" && text !== null) ?
                    <Tooltip placement="topLeft" title={text.specTypeName}>
                        {(text.specTypeName.length >= 8 ?
                            text.specTypeName.substring(0, 8) + '......' :
                            text.specTypeName)}
                    </Tooltip>
                    : ''
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
        {
            title: '规格内容',
            dataIndex: 'productSpecValues',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary"
                        onClick={() => hanldProductSpecValue(record.id)}>
                        {
                            getProductSpecValues(text)
                        }</Button>
                </Space>
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(true);
        // ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        closeModal(false);
        // ref.current.handleCancel();  
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品规格',
        apiId,
        columns,
        showStates: true,
        EditComponent: WebProductSpecEdit,
        sendPageData: { productId: data.id },
    };

    return (
        <>
            <AntDraggableModal
                title={'产品规格'}
                open={true}
                okText='退出'
                cancelText='取消'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={900}>
                <WebUniversal props={extendProps} ref={ref} ></WebUniversal>
            </AntDraggableModal>
            {modalProductSpecValueVisible
                ? (<WebProductSpecValue
                    closeModal={onCloseModalProductSpecValueVisible}
                    data={productSpecValueData} />)
                : null}
        </>
    )
}

export default WebProductSpec;