import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx'
import fetchApi from '../../utils/api/fetchApi';
import getList from '../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [payModeData, setPayModeData] = useState<any>();
    const [payProcessData, setPayProcessData] = useState<any>();
    const [refundProcessData, setRefundProcess] = useState<any>();

    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'payMode@payProcess@refundProcess',
                    apiExtend: 'showLists'
                }).then(res => {
                    if (res.success) {
                        const { payMode, payProcess, refundProcess } = res.data;
                        setPayModeData(payMode);
                        setPayProcessData(payProcess);
                        setRefundProcess(refundProcess)
                    }
                })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'payOrder',
        serviceId: 'pay',
        dateColumnName: ['payDate', 'lastPayTime', 'receivedTime']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '支付订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={900}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>

                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="方式"
                                name="payModeId"
                                rules={[{ required: true, message: '支付方式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="支付方式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(payModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="金额"
                                name="payAmount"
                                rules={[{ required: true, message: '请输入支付金额!' }]}>
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="未退款"
                                name="unreimbursedAmount">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="进程"
                                name="payProcessId"
                                rules={[{ required: true, message: '支付进程!' }]}>
                                <Select
                                    showSearch
                                    placeholder="支付进程"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(payProcessData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="支付时间"
                                name="payDate">
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="退款进程"
                                name="refundProcessId"
                                rules={[{ required: true, message: '退款进程!' }]}>
                                <Select
                                    showSearch
                                    placeholder="退款进程"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(refundProcessData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="最后支付时间"
                                name="lastPayTime">
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="商户id"
                                name="merchantId"
                                rules={[{ required: true, message: '请输入公司商户id!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="商户名"
                                name="merchantName"
                                rules={[{ required: true, message: '请输入公司商户名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="商户单类型"
                                name="merchantOrderType"
                                rules={[{ required: true, message: '请输入公司商户单类型!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="返回地址"
                                name="returnedAddress"
                                rules={[{ required: true, message: '请输入成功返回信息地址!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="商户单号"
                                name="merchantOrderId"
                                rules={[{ required: true, message: '请输入公司商户单号!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="接收时间"
                                name="receivedTime">
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="接收"
                                name="isReceived"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="已接收"
                                    unCheckedChildren="未接收" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="商品详情"
                                name="commodityDetails">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="支付成功"
                                name="isPaySuccess"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;