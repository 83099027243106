import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WangeditorV5 from '../../../utilsTsx/components/wangeditor/WangeditorV5'
import fetchApi from '../../../utils/api/fetchApi';
import systematic from '../../../config/systematic';

const WebProductDetails = (props: any) => {
    const { data, onClose } = props;
    const { id } = data;
    /** 后端数据defaultService */
    const defaultService = systematic.bmService;
    /**
     * 获取数据
     * @param currentId 
     * @returns 
     */
    const onGetContent = async (currentId: string) => {
        const rtn = await fetchApi.GET(defaultService, {
            apiId: 'webProduct',
            apiVariable: currentId,
            apiExtend: 'showRichText'
        });
        return rtn;
    };
    /**
     * 保存数据
     * @param currentId 
     * @param contentHtml 
     * @returns 
     */
    const onSaveContent = async (currentId: string, contentHtml: string) => {
        const rtn = await fetchApi.PUT(defaultService, {
            apiId: 'webProduct',
            apiVariable: currentId,
            apiExtend: 'updateRichText',
            apiData: { details: contentHtml }
        });
        return rtn;
    };
    return (
        <>
            <AntDraggableModal
                title={`${(data.type === 'NEW' ? '新增' : '编辑')}产品详情[${data.productName}]`}
                open={true}
                closable={false}
                maskClosable={false}
                width={470}
                footer={false}
                centered={true}>
                <WangeditorV5
                    key={id}
                    id={id}
                    serviceUrl={systematic.bmService}
                    onClose={onClose}
                    onGetContent={onGetContent}
                    onSaveContent={onSaveContent} />
            </AntDraggableModal>
        </>
    )
}
export default WebProductDetails;