import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebProductEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [brandData, setBrandData] = useState<any>();
    const [productTypeData, setProductTypeData] = useState<any>();
    const [shopData, setShopData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }

        const fetchData = async () => {
            httpGet(defaultService,
                {
                    apiId: 'webProductType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setProductTypeData(res.data);
                    }
                });
            fetchApi.GET(defaultService, {
                apiId: 'brand',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBrandData(res.data)
                }
            });
            httpGet(defaultService,
                {
                    apiId: 'shop',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setShopData(res.data);
                    }
                });
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webProduct',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '产品'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={4}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={20}>
                            <Form.Item
                                label="名称"
                                name="productName"
                                rules={[{ required: true, message: '产品名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="品牌"
                                name="brandId"
                                rules={[{ required: true, message: '品牌!' }]}>
                                <Select
                                    showSearch
                                    placeholder="品牌"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(brandData, 'brandName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="店铺"
                                name="shopId"
                                rules={[{ required: true, message: '店铺!' }]}>
                                <Select
                                    showSearch
                                    placeholder="店铺"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(shopData, 'shopName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="分类"
                                name="productTypeId"
                                rules={[{ required: true, message: '产品分类!' }]}>
                                <Select
                                    showSearch
                                    placeholder="产品分类"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(productTypeData, 'typeName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="广告词"
                                name="advertising">
                                <Input.TextArea
                                    placeholder="广告词"
                                    rows={4}>
                                </Input.TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="月销量"
                                name="monthlyQuantitySold">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="广告"
                                name="isAd"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebProductEdit;