import { RouteObject } from "react-router-dom"
//sms
import SmsService from '../components/messageCenter/sms/SmsService';
import SmsManage from '../components/messageCenter/sms/SmsManage';
//wx
import WxSubscribeMessageType from '../components/messageCenter/wx/subscribe/WxSubscribeMessageType';
import WxSubscribeMessage from '../components/messageCenter/wx/subscribe/WxSubscribeMessage';
import WxSubscribeMessageUser from '../components/messageCenter/wx/subscribe/WxSubscribeMessageUser';

import WxSubscribeMessageV2Type from '../components/messageCenter/wx/subscribe/WxSubscribeMessageV2Type';
import WxSubscribeMessageV2User from '../components/messageCenter/wx/subscribe/WxSubscribeMessageV2User';
import WxSubscribeMessageV2 from '../components/messageCenter/wx/subscribe/WxSubscribeMessageV2';

//客服信息
import CustomerServiceOperator from '../components/messageCenter/wx/customerService/CustomerServiceOperator';

const messageRouters: RouteObject[] = [
    //sms
    {
        path: "/smsService",
        element: <SmsService />,
    },
    {
        path: "/smsManage",
        element: <SmsManage />,
    },
    //wx
    {
        path: "/wxSubscribeMessageType",
        element: <WxSubscribeMessageType />,
    },
    {
        path: "/wxSubscribeMessage",
        element: <WxSubscribeMessage />,
    },
    {
        path: "/wxSubscribeMessageUser",
        element: <WxSubscribeMessageUser />,
    },
    {
        path: "/wxSubscribeMessageV2Type",
        element: <WxSubscribeMessageV2Type />,
    },
    {
        path: "/wxSubscribeMessageV2User",
        element: <WxSubscribeMessageV2User />,
    },
    {
        path: "/wxSubscribeMessageV2",
        element: <WxSubscribeMessageV2 />,
    },
    {
        path: "/customerServiceOperator",
        element: <CustomerServiceOperator />,
    },

]

export default messageRouters;