import { Form, Input, Switch, Row, Col, Select } from 'antd';
import { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const WebCarouselAdFileEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const [webCarouselAdData, setWebCarouselAdData] = useState<any>();
    const [productData, setProductData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    const productId = res.data.productId;
                    const carouselAdId = res.data.carouselAdId;
                    if (productId) {
                        fetchApi.GET(defaultService,
                            {
                                apiId: 'webProduct',
                                apiVariable: productId
                            }).then(res => {
                                if (res.success) {
                                    setProductData([res.data]);
                                }
                            })
                    }
                    if (carouselAdId) {
                        fetchApi.GET(defaultService,
                            {
                                apiId: 'webCarouselAd',
                                apiVariable: carouselAdId
                            }).then(res => {
                                if (res.success) {
                                    setWebCarouselAdData([res.data]);
                                }
                            })
                    }
                }

            })
        }
        // const fetchData = async () => {
        // }
        // fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webCarouselAdFile',
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '广告图片'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={4}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="广告"
                                name="carouselAdId"
                                rules={[{ required: false, message: '广告!' }]}>
                                <Select
                                    showSearch
                                    placeholder="广告"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    disabled={true}>
                                    {getList.general(webCarouselAdData, 'adName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="产品"
                                name="productId"
                                rules={[{ required: false, message: '产品!' }]}>
                                <Select
                                    showSearch
                                    placeholder="产品"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('webProduct', setProductData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(productData, 'productName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="广告内容"
                                name="contents"
                                rules={[{ required: true, message: '广告内容!' }]}>
                                <Input.TextArea
                                    placeholder="广告内容"
                                    rows={4}>
                                </Input.TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="文件id"
                                name="fileId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={17}>
                            <Form.Item
                                label="文件目录"
                                name="fileDestination">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="文件类型"
                                name="fileType">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="文件大小"
                                name="fileSize">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        
                        <Col span={5}>
                            <Form.Item
                                label="图片宽度"
                                name="imageWidth">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="文件高度"
                                name="imageHeight">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="默认显示"
                                name="isDefault"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebCarouselAdFileEdit;



