import { Switch, Tooltip } from 'antd';
import { useEffect, useRef } from 'react';
// import WebSubscribeMessageEdit from './WebSubscribeMessageEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 WebU
const WebU = (props: any) => {

    const apiId = 'webSubscribeMessage';
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 更改一项数据 */
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '类型',
            dataIndex: 'subscribeMessageTypeId_Name',
        },
        {
            title: '微信',
            dataIndex: 'wxUser',
            render: (text: any) => (
                text
                    ? text.nickName +
                    '(' + text.mobile + ')'
                    : ''
            )
        },
        {
            title: 'useId',
            dataIndex: 'useId',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 30 ? text.substring(0, 30) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '内容',
            dataIndex: 'messageData',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 30 ? text.substring(0, 30) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '是否发送',
            dataIndex: 'isSend',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isSend', e, apiId)}
                />
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '订阅信息',
        apiId,
        columns,
        showAdd: false,
        showOperation: false
        // EditComponent: WebSubscribeMessageEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebU;