import { Form, Input, Switch, Row, Col, Select, DatePicker } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal from '../../system/WebEditUniversal';
import httpUrl from '../../../config/httpUrl';
import { httpGet } from '../../../utils/api/fetchApi';
import moment from 'moment';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const CompanyFileEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const [company, setCompany] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            httpGet(await httpUrl.general('company', undefined, 'ShowList')).then(res => {
                if (res.success) {
                    setCompany(res.data);
                }
            });
        };
        fetchData();
    }, []);

    const { Option } = Select;
    const getCompanyList = (datas: any) => {
        if (typeof datas !== "undefined") {
            return (datas.map((item: any, key: number) => {
                return <Option value={item.id} key={key}>{item.companyName}</Option>
            })
            )
        }
    };

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: any = {
        ...props,
        form,
        apiName: 'companyFile',
        dateColumnName: ['registeredDate', 'endDate'],
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '公司文件'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={4}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="公司"
                                name="companyId"
                                rules={[{ required: false, message: '请选择公司!' }]}>
                                <Select
                                    showSearch
                                    placeholder="请选择公司"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    disabled={true}>
                                    {getCompanyList(company)}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="文件路径"
                                name="filePath">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="文件编号"
                                name="fileId">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="文件名称"
                                name="fileName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="内容"
                                name="content">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="注册日期"
                                name="registeredDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="有效日期"
                                name="endDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default CompanyFileEdit;