import WebGiftCardConsumptionListEdit from './WebGiftCardConsumptionListEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch, Tooltip } from 'antd';
import { useEffect, useRef } from 'react';
import moment from 'moment';

const WebGiftCard = (props: any) => {
    const apiId = 'webGiftCardConsumptionList';
    /** 更改一项数据 */
    let handleChangeOne: any;
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);

    useEffect(() => {
        /** ref.current 为空，因为只有在函数返回并呈现内容之后才设置ref。
        每当传递给useffect钩子的数组的内容值发生更改时，
        它都会触发useffect钩子。
        通过在数组中传递 handleChangeOne 并将记录 handleChangeOne 的回调传递到控制台，
        可以利用useffect钩子来完成任务。*/
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // initData = ref.current.initData;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '卡号',
            dataIndex: 'giftMemberCardId',
        },
        {
            title: '原金额',
            dataIndex: 'originalAmount'
        },
        {
            title: '消费金额',
            dataIndex: 'amount'
        },
        {
            title: '余额',
            dataIndex: 'balance'
        },
        {
            title: '退单',
            dataIndex: 'isChargeback',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChangeOne(record.id, 'isPhysicalCard', e, apiId)}
                />
            )
        },
        {
            title: '备注',
            dataIndex: 'memo',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 15 ? text.substring(0, 15) + '......' : text)
                    : ''
            }</Tooltip>
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '礼品卡消费清单',
        apiId,
        columns,
        showStates: false,
        showAdd: false,
        showOperation: false,
        EditComponent: WebGiftCardConsumptionListEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebGiftCard;
