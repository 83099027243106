import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from "../../universal/getList";
import AddressEdit from '../../system/AddressEdit';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebLogisticsWarehouseEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [LWHTypeData, setLWHTypeData] = useState<any>();
    const [shopData, setShopData] = useState<any>();
    const [row, setRow] = useState<any>();

    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'LWHType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setLWHTypeData(res.data);
                    }
                })
        }
        //由父组件返回的数据
        if (ref.current) {
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    setRow(res.data);
                    const shopId = res.data.shopId;
                    fetchApi.GET(defaultService, {
                        apiId: 'shop',
                        apiVariable: shopId,
                    }
                    ).then(res => {
                        if (res.success) {
                            setShopData([res.data]);
                        }
                    });
                }
            })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webLogisticsWarehouse'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '物流仓库'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="店铺"
                                name="shopId"
                                rules={[{ required: true, message: '店铺!' }]}>
                                <Select
                                    showSearch
                                    placeholder="请输入店铺名称查找"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('shop', setShopData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(shopData, 'shopName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="仓库名"
                                name="LWHName"
                                rules={[{ required: true, message: '仓库名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="仓库类型"
                                name="LWHTypeId"
                                rules={[{ required: true, message: '仓库类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="仓库类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(LWHTypeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="联系人"
                                name="contacts"
                                rules={[{ required: true, message: '联系人!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="联系电话"
                                name="contactPhone"
                                rules={[{ required: true, message: '联系电话!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <AddressEdit isRequired={true} row={row} form={form} />

                        <Col span={24}>
                            <Form.Item
                                label="详细地址"
                                name="detail"
                                rules={[{ required: true, message: '详细地址!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="邮编"
                                name="postalCode">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="默认"
                                name="isDefault"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebLogisticsWarehouseEdit;