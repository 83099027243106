import { Col, DatePicker, Form, Input, QRCode, Row, Select, Switch, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [tradeTypeData, setTradeTypeData] = useState<any>();
    const [wxAccountWxPayData, setWxAccountWxPayData] = useState<any>();
    const [wxAccountWxData, setWxAccountWxData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            // ref.current.row.then((res:any)=>{
            // })

        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'tradeType',
                    apiExtend: 'showLists'
                }).then(res => {
                    if (res.success) {
                        const { tradeType } = res.data;
                        setTradeTypeData(tradeType);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiExtend: 'wxAccountList',
                    apiData: {
                        slectType: 'wxPay'
                    }
                }).then(res => {
                    if (res.success) {
                        setWxAccountWxPayData(res.data);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiExtend: 'wxAccountList',
                    apiData: {
                        slectType: 'wx@wxMiniApp'
                    }
                }).then(res => {
                    if (res.success) {
                        setWxAccountWxData(res.data);
                    }
                })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'wxPayOrder',
        serviceId: 'pay',
        dateColumnName: ['tradeTime']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '微信支付单'}
                open={true}
                okText={'确定'}
                // okButtonProps={{ style: { display: "none" } }}
                cancelText={'关闭'}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={900}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>

                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="交易类型"
                                name="tradeTypeId"
                                rules={[{ required: true, message: '交易类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="交易类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(tradeTypeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="来源"
                                name="wxSourceId"
                                rules={[{ required: true, message: '微信来源"!' }]}>
                                <Select
                                    showSearch
                                    placeholder="微信来源"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(wxAccountWxData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="支付"
                                name="wxPayId"
                                rules={[{ required: true, message: '微信支付!' }]}>
                                <Select
                                    showSearch
                                    placeholder="微信支付"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(wxAccountWxPayData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="交易单号"
                                name="transactionNo">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="随机字符"
                                name="nonceStr">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Tooltip placement="topLeft" title={form.getFieldValue('payInfo')}>
                                <Form.Item
                                    label="支付信息"
                                    name="payInfo">
                                    <Input />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="wxSPOpenId"
                                name="wxSPOpenId"
                                tooltip='服务商的用户openId'>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="wxOpenId"
                                name="wxOpenId"
                                tooltip='商户/子商户的用户openId'>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="交易时间"
                                name="tradeTime">
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        {form.getFieldValue('tradeTypeId') === 'NATIVE' ?
                            <Col span={6}>
                                <Form.Item
                                    label="扫码支付"
                                    name="payInfo"
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <QRCode value={form.getFieldValue('payInfo')}></QRCode>
                                </Form.Item>
                            </Col> : ''}

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;