import { useEffect, useRef, useState } from 'react';
import WxSubscribeMessageV2Edit from './WxSubscribeMessageV2Edit';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import { Switch, Tooltip } from 'antd';

/**
 * 组件 
 * @param props 
 * @returns 
 */
const Web = (props: any) => {
    const apiId = 'wxSubscribeMessageV2';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;
    /** 更改一项数据 */
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '微信用户',
            dataIndex: 'wxUser',
            render: (text: any) => (text ? text.nickName : '')
        },
        {
            title: '状态id',
            dataIndex: 'curStatus'
        },
        {
            title: '信息',
            dataIndex: 'content_json',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 30 ? text.substring(0, 30) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '发送',
            dataIndex: 'isSend',
            render: (text: any, record: any) => (
                <Switch checkedChildren="成功" unCheckedChildren="失败"
                    checked={text} key={record.id}
                // onChange={(e) => handleChangeOne(record.id, 'isSend', e, apiId)}
                />
            )
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '微信订阅信息V2',
        apiId,
        columns,
        showStates: false,
        EditComponent: WxSubscribeMessageV2Edit,
        serviceId: 'business',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Web;