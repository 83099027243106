import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { useState, useEffect } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { Boot, IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import { IModuleConf } from '@wangeditor/core'
import { IButtonMenu, IMenuGroup } from '@wangeditor/core/dist/core/src/menus/interface';
import { Button, message, Modal, Space } from 'antd';
import { defaultRtn, rtnType } from '../../../utilsTs/declareTypes';
import dayjs from 'dayjs';
import PhotoGallery from '../../../components/filesSystem/PhotoGallery';
import systemExpand from '../../../config/systemExpand';
import fetchApi from '../../../utils/api/fetchApi';

/** 选项类型 */
declare type WangeditorOptionsType = {
    /** id */
    id: string,
    /** 关闭窗口方法 */
    onClose: Function,
    /** 服务地址 */
    serviceUrl: string,
    /** 获取内容方法 */
    onGetContent?: (
        /** id */
        id: string) => Promise<rtnType>,
    /** 保存内容方法 */
    onSaveContent?: (
        /** id */
        id: string,
        /** html内容 */
        contentHtml: string) => Promise<rtnType>
};

/** 原内容 */
let originalContent: string;

/**
 * 富文本编辑器WangeditorV5
 * @param options 选项 
 * @returns 
 */
const WangeditorV5 = (options: WangeditorOptionsType) => {
    const { id, onClose, serviceUrl, onGetContent, onSaveContent } = options;
    //本地储存当前id
    localStorage.setItem('currentId', id);
    /** 获取当前id */
    const getCurrentId = () => {
        let currentId = '';
        const res = localStorage.getItem('currentId');
        if (res) {
            currentId = res;
        }
        return currentId;
    };
    /** 本地储存id */
    const getLocalStorageId = () => {
        return `Wangeditor_${getCurrentId()}`;
    };

    //图库
    const [photoGalleryVisible,
        setPhotoGalleryVisible] = useState(false);
    const [filesGroupData, setFilesGroupData] = useState<any>([]);
    const [defaultMenu, setDefaultMenu] = useState<any>();
    const [editorData, setEditorData] = useState<any>(() => { });
    /**
     * 关闭图库
     */
    const closePhotoGallery = (state: boolean) => {
        setPhotoGalleryVisible(false);
    };
    /**
     * 获取选择好的图片，并插入到编辑框
     */
    const getCheckPhoto = (data: any, insertFn: any) => {
        data.map(async (item: any, index: any) => {
            const { id, src } = item;
            insertFn(src, id, src);
        })
    }
    /**
     * 打开图库
     */
    const openPhotoGallery = async (insertFn: any) => {
        const configRes = await systemExpand.filesServer();
        if (configRes.success === true) {
            const { general } = configRes.data;
            if (typeof general !== 'undefined') {
                const { filesServerId, filesServiceId } = general;
                //由于没有选择默认图库的组，所以提前设定第一个组为默认
                fetchApi.GET(serviceUrl,
                    {
                        apiId: 'filesGroup',
                        apiExtend: 'showList'
                    }, {
                    filesServiceId
                }).then(res => {
                    if (res.success === true) {
                        res.data.map(async (item: any, index: any) => {
                            if (index === 0) {
                                //读取数据库，首先将分组先set好
                                fetchApi.GET(serviceUrl,
                                    {
                                        apiId: 'filesGroup',
                                        apiExtend: 'showList'
                                    }, {
                                    filesServiceId
                                }).then(res => {
                                    if (res.success === true) {
                                        setFilesGroupData(res.data);
                                    }
                                })
                                //将默认分组也先set好
                                setDefaultMenu(item.id);
                                //将editor也set好，将值传到下一个组件使用，方便将回传回来的图片插入到编辑器中
                                setEditorData(() => insertFn);
                                setPhotoGalleryVisible(true);
                            }
                        })
                    }
                })
            }
        }
    };

    //---------------------


    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null);
    // 编辑器内容
    const [html, setHtml] = useState<any>();
    /** 首次load */
    let fristLoad = true;
    /** 缓存类型 */
    type localStorageType = {
        /** 数据 */
        data?: string,
        /** 更新时间 */
        updateAt?: Date
    };
    /**
    * 保存临时文件
    * @param data
    */
    const saveLocalStorage = (data?: string) => {
        let obj: localStorageType = {
            data,
            updateAt: new Date()
        }
        localStorage.setItem(getLocalStorageId(), JSON.stringify(obj));
    };

    /**
     * 获取临时文件
     */
    const getLocalStorage = (): localStorageType => {
        let objItem = localStorage.getItem(getLocalStorageId());
        let obj: localStorageType = {};
        if (typeof objItem !== "undefined" && objItem !== null) {
            obj = JSON.parse(objItem);
        }
        return obj;
    };

    /**
     * 保存数据
     * @param contentHtml html内容 
     * @returns 
     */
    const saveContent = async (contentHtml: string) => {
        let rtn = defaultRtn({ success: false, message: '不存在保存方法' });
        if (onSaveContent) {
            rtn.message = '';
            rtn = await onSaveContent(getCurrentId(), contentHtml);
            if (rtn.success === true) {
                saveLocalStorage(contentHtml);
                originalContent = contentHtml;
            }
        }
        return rtn;
    };

    /** 菜单: 保存数据 */
    class SaveCustomMenu implements IButtonMenu {
        /** 标题 */
        title: string;
        /** 标记 */
        tag: string;
        constructor() {
            this.title = '保存'; // 自定义菜单标题
            this.tag = 'button';
        }
        // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
        getValue(editor: IDomEditor): string | boolean {
            return 'save';
        }
        // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
        isActive(editor: IDomEditor): boolean {
            return false;
        }
        // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
        isDisabled(editor: IDomEditor): boolean {
            return false;
        }
        // 点击菜单时触发的函数
        async exec(editor: IDomEditor, value: string | boolean) {
            if (editor) {
                editor.hidePanelOrModal();
            }
            const saveRes = await saveContent(editor.getHtml());
            if (saveRes.success === true) {
                message.success('成功保存内容！');
            } else {
                message.error(`保存失败！请检查！${saveRes.message}`);
            }
        }
    };

    /** 菜单: 退出 */
    class ExitCustomMenu implements IButtonMenu {
        /** 标题 */
        title: string;
        /** 标记 */
        tag: string;
        constructor() {
            this.title = '退出'; // 自定义菜单标题
            this.tag = 'button';
        }
        // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
        getValue(editor: IDomEditor): string | boolean {
            return 'exit';
        }
        // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
        isActive(editor: IDomEditor): boolean {
            return false;
        }
        // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
        isDisabled(editor: IDomEditor): boolean {
            return false;
        }
        // 点击菜单时触发的函数
        exec(editor: IDomEditor, value: string | boolean) {
            /** 关闭 */
            const onCloseEx = (modal?: any) => {
                if (onClose) {
                    onClose();
                    if (modal) {
                        modal.destroy();
                    }
                    editor.destroy();
                    setEditor(null);
                    saveLocalStorage(undefined);
                }
            };
            /** 退出 */
            const showLogoutConfirm = () => {
                const modal = Modal.confirm({
                    title: '确认退出',
                    centered: true,
                    content: (<p>您确定要退出？当前内容已改变，未保存的数据将会丢失！</p>),
                    footer: (
                        //<Space> 是一个 Flex 容器，可以通过设置父容器的 justifyContent: 'flex-end' 使其子元素整体右对齐
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Space>
                                <Button key="temp" type='dashed' onClick={() => {
                                    onCloseEx(modal);
                                }}>不保存退出</Button>
                                <Button key="cancel" onClick={() => modal.destroy()}>取消</Button>
                                <Button key="submit" type="primary" onClick={async () => {
                                    const saveRes = await saveContent(editor.getHtml());
                                    if (saveRes.success === true) {
                                        message.success('成功保存内容！');
                                        onCloseEx(modal);
                                    } else {
                                        message.error(`保存失败！请检查！${saveRes.message}`);
                                    }
                                }}>保存并退出</Button>
                            </Space>
                        </div>
                    )
                });
            };
            if (editor.getHtml() !== originalContent && typeof originalContent !== 'undefined') {
                showLogoutConfirm();
            }
            else {
                onCloseEx();
            }
        }
    };

    /** 自定义文件菜单组 */
    class FileCustomMenuGroup implements IMenuGroup {
        /** 主题 */
        title: string;
        /** 键 */
        key: string;
        /** 菜单集合 */
        menuKeys: Array<string>;
        constructor() {
            this.title = '文件';
            this.key = 'fileCustomMenuGroup';
            this.menuKeys = ['saveCustomMenu', 'exitCustomMenu'];
        }
    };

    useEffect(() => {
        /** 保存菜单 */
        const saveCustomMenuConf = {
            key: 'saveCustomMenu',
            factory() {
                return new SaveCustomMenu();
            }
        }
        /** 退出菜单 */
        const exitCustomMenuConf = {
            key: 'exitCustomMenu',
            factory() {
                return new ExitCustomMenu();
            }
        }
        const module: Partial<IModuleConf> = {
            menus: [saveCustomMenuConf, exitCustomMenuConf],
        }
        try {
            Boot.registerModule(module);
        }
        catch { }
    }, [])

    /** 工具栏配置 */
    const toolbarConfig: Partial<IToolbarConfig> = {
        insertKeys: {
            index: 0, // 插入的位置，基于当前的 toolbarKeys
            keys: [new FileCustomMenuGroup],
        },
    };

    /** 编辑器配置 */
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入内容...',
        MENU_CONF: {}
    };

    /** 插入图片类型 */
    type InsertFnType = (url: string, alt: string, href: string) => void
    editorConfig.MENU_CONF!['uploadImage'] = {
        // 自定义选择图片
        customBrowseAndUpload(insertFn: InsertFnType) {
            openPhotoGallery(insertFn);
        },
    };

    // 编辑器内容改变
    editorConfig.onChange = (editor: IDomEditor) => {
        if (fristLoad === true) {
            fristLoad = false;
        } else {
            const contentHtml = editor.getHtml();
            saveLocalStorage(contentHtml);
        }
    };

    // 及时销毁 editor ，重要！
    useEffect(() => {
        /** 获取内容 */
        const getContent = async () => {
            if (onGetContent) {
                const res = await onGetContent(getCurrentId());
                if (res.success === true) {
                    originalContent = res.data;
                    /** 临时内容 */
                    const tempContent = getLocalStorage();
                    if (typeof tempContent === 'undefined') {
                        if (editor) {
                            if (typeof originalContent !== 'undefined') {
                                editor.setHtml(originalContent);
                            }
                        }
                    } else {
                        const { data, updateAt } = tempContent;
                        if (originalContent !== data && typeof data !== 'undefined') {
                            const tempModal = Modal.confirm({
                                title: '临时文件未保存',
                                content: `是否使用上次临时文件？最后修改时间: ${updateAt ?
                                    dayjs(updateAt).format('YYYY-MM-DD HH:mm:ss') : ''
                                    }`,
                                okText: '确定',
                                cancelText: '取消',
                                onOk() {
                                    tempModal.destroy();
                                    if (typeof data !== 'undefined') {
                                        if (editor) {
                                            editor.setHtml(data);
                                            saveLocalStorage(data);
                                        }
                                    }
                                },
                                onCancel() {
                                    tempModal.destroy();
                                    if (editor) {
                                        if (typeof originalContent !== 'undefined') {
                                            editor.setHtml(originalContent);
                                        }
                                    }
                                }
                            });
                        } else {
                            if (editor) {
                                if (typeof originalContent !== 'undefined') {
                                    editor.setHtml(originalContent);
                                }
                            }
                        }
                    }
                }
            }
        };

        if (editor) {
            getContent();
        }
        return () => {
            if (editor == null) return;
            editor.destroy();
            setEditor(null);
        }
    }, [editor])

    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100 }}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={(editor) => setHtml(editor.getHtml())}
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>
            <div style={{ marginTop: '15px' }}>{html}</div>
            {photoGalleryVisible
                ? (<PhotoGallery
                    closeModal={closePhotoGallery}
                    filesGroupData={filesGroupData}
                    defaultMenu={defaultMenu}
                    editorData={editorData}
                    getCheckPhoto={getCheckPhoto}
                />)
                : null}
        </>
    )
};

export default WangeditorV5;