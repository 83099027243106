import { Form, Input, Row, Col, Switch, Select, DatePicker, Tooltip, Checkbox } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import moment from 'moment';
import AddressEdit from '../../system/AddressEdit';


//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebLogisticsOrderEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const [logisticsTypeData, setLogisticsTypeData] = useState<any>();
    const [deliveryModeData, setDeliveryModeData] = useState<any>();
    const [logisticsCompanyData, setLogisticsCompanyData] = useState<any>();
    const [row, setRow] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        //由父组件返回的数据
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    setRow(res.data);
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'logisticsType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setLogisticsTypeData(res.data);
                    }
                });
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'deliveryMode',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDeliveryModeData(res.data);
                    }
                });
            let res = await fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'logisticsCompany',
                    apiExtend: 'showList'
                })
            if (res.success === true) {
                setLogisticsCompanyData(res.data);
            }
        };
        fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webLogisticsOrder',
        dateColumnName: ['consignDate']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '物流订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={900}
            >

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={7}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="物流模式"
                                name="logisticsTypeId"
                                rules={[{ required: true, message: '物流模式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="物流模式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(logisticsTypeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="发货模式"
                                name="deliveryModeId"
                                rules={[{ required: true, message: '发货模式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="发货模式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(deliveryModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Tooltip placement="topLeft"
                                title={'分拆发货模式时必填，用于标识分拆发货模式下是否已全部发货完成，只有全部发货完成的情况下才会向用户推送发货完成通知'}>
                                <Form.Item
                                    label="发货完成:"
                                    name="isAllDelivered"
                                    initialValue={true} >
                                    <Checkbox defaultChecked={true} />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Tooltip placement="topLeft"
                                title={data.transactionNo}>
                                <Form.Item
                                    label="交易号:"
                                    name="transactionNo"
                                    initialValue={data.transactionNo}
                                    rules={[{ required: true, message: '原支付交易对应的微信订单号!' }]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="物流公司"
                                name="logisticsCompanyId"
                                rules={[{ required: true, message: '物流公司!' }]}>
                                <Select
                                    showSearch
                                    placeholder="物流公司"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(logisticsCompanyData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="运单号"
                                name="logisticsOrderNo"
                                rules={[{ required: true, message: '物流(快递)公司的运单号!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="发货人"
                                name="consignor"
                                rules={[{ required: true, message: '发货人!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="发货电话"
                                name="consignPhone"
                                rules={[{ required: true, message: '发货电话!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="发货日期"
                                name="consignDate"
                                initialValue={moment(new Date())}
                                rules={[{ required: true, message: '发货日期!' }]}>
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <AddressEdit isRequired={true} row={row} form={form} visibleList={{
                            countryOrAreaVisible: true,
                            countryOrAreaCodeName: 'consignCountryOrAreaCode',
                            countryOrAreaSpan: 6
                        }} />

                        <Col span={6}>
                            <Form.Item
                                label="省:"
                                name="consignProvince"
                                rules={[{ required: true, message: '省!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="城市:"
                                name="consignCity"
                                rules={[{ required: true, message: '城市!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="区.县:"
                                name="consignCounty"
                                rules={[{ required: true, message: '区.县!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="街镇:"
                                name="consignTown">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="发货地址"
                                name="consignDetail"
                                rules={[{ required: true, message: '发货地址!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="收货人"
                                name="receiver"
                                rules={[{ required: true, message: '收货人!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="收货电话"
                                name="receivePhone"
                                rules={[{ required: true, message: '收货电话!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="邮编"
                                name="postalCode"
                                rules={[{ required: true, message: '邮编!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <AddressEdit isRequired={true} row={row} form={form} visibleList={{
                            countryOrAreaVisible: true,
                            countryOrAreaCodeName: 'receiveCountryOrAreaCode',
                            countryOrAreaSpan: 6
                        }} />

                        <Col span={6}>
                            <Form.Item
                                label="省:"
                                name="receiveProvince"
                                rules={[{ required: true, message: '省!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="城市:"
                                name="receiveCity"
                                rules={[{ required: true, message: '城市!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="区.县:"
                                name="receiveCounty"
                                rules={[{ required: true, message: '区.县!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="街镇:"
                                name="receiveTown">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="收货地址"
                                name="receiveDetail"
                                rules={[{ required: true, message: '收货地址!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="货品详情"
                                name="goodsDetails"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={20}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>

            </AntDraggableModal>
        </>
    )
}

export default WebLogisticsOrderEdit;