import { useEffect, useRef, useState } from 'react';
import EditComponent from './CustomerServiceOperatorEdit';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import { Tooltip } from 'antd';

/**
 * 组件 
 * @param props 
 * @returns 
 */
const Web = (props: any) => {
    const apiId = 'customerServiceOperator';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;
    /** 更改一项数据 */
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'csoName',
        },
        {
            title: '微信',
            dataIndex: 'wxAccount_Name',
        },
        {
            title: '推送地址',
            dataIndex: 'pushAddress',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '客服使用者',
        apiId,
        columns,
        showStates: true,
        EditComponent,
        serviceId: 'business',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Web;