import { Form, Input, Row, Col, Switch } from 'antd';
import { useRef, useEffect } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm();
    const { data } = props;
    const ref = useRef<any>(undefined);

    useEffect(() => {
        // const fetchData = async () => {
        // };
        // fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'membershipType',
        serviceId: 'membership'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '会员类别'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="名称"
                                name="typeName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>



                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;