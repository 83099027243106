import { Form, Input, Row, Col, Switch, Select, DatePicker } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import moment from 'moment';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebUserCouponEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [wxUserData, setWxUserData] = useState<any>();
    const [couponData, setCouponData] = useState<any>();
    const [userCouponStateData, setUserCouponStateData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then(async (res: any) => {
                if (typeof res !== 'undefined') {
                    if (res.success === true) {
                        const unionId = res.data.unionId;
                        const couponId = res.data.couponId;
                        fetchApi.GET(defaultService,
                            {
                                apiId: 'webWxUser',
                                apiVariable: unionId
                            }).then(res => {
                                if (res.success) {
                                    setWxUserData([res.data]);
                                }
                            });
                        fetchApi.GET(defaultService,
                            {
                                apiId: 'webCoupon',
                                apiVariable: couponId
                            }).then(res => {
                                if (res.success) {
                                    setCouponData([res.data]);
                                }
                            });
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'userCouponState',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setUserCouponStateData(res.data);
                    }
                });
        };

        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webUserCoupon',
        dateColumnName: ['collectDate',
            'useDate']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '用户优惠券'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="微信unionId"
                                name="unionId"
                                rules={[{ required: true, message: '微信unionId!' }]}>
                                <Select
                                    showSearch
                                    placeholder="微信unionId"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(wxUserData, 'nickName', 'unionId')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="优惠券"
                                name="couponId"
                                rules={[{ required: true, message: '优惠券!' }]}>
                                <Select
                                    showSearch
                                    placeholder="优惠券"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(couponData, 'couponName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="领取日期"
                                name="collectDate">
                                <DatePicker
                                    disabled={true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="使用日期"
                                name="useDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="产品订单"
                                name="productOrderId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="支付订单"
                                name="orderPayId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="使用"
                                name="userCouponStateId">
                                <Select
                                    showSearch
                                    placeholder="优惠券状态"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(userCouponStateData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebUserCouponEdit;