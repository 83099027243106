import React, { useRef, useState } from 'react';
import UserEdit from './UserEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { Switch, Space, Button, Tooltip } from 'antd';
import RoleOrUserLicense from '../license/RoleOrUserLicense';
import { columnsheadId } from '../../universal/columnshead';
import RoleOrUserLicenseEx from '../license/RoleOrUserLicenseEx';

//组件 User 
const User = (props: any) => {

    const [modalUserLicense, setModalUserLicense] = useState(false);
    const [modalUserLicenseEx, setModalUserLicenseEx] = useState(false);
    const [userInfo, setUserInfo] = useState<any>();
    const ref = useRef<any>(undefined);
    const handleUserLicense = (record: any) => {
        const userId = record.id;
        setUserInfo({
            roleOrUserId: userId,
            itemName: record.userName
        });
        setModalUserLicense(true);
    };
    //子组件回调函数，关闭modal
    const onCloseModalUserLicense = (state: boolean) => {
        setModalUserLicense(false);
    };

    /** 子组件回调函数，关闭用户权根扩展 */
    const onCloseModalUserLicenseEx = () => {
        setModalUserLicenseEx(false);
    };

    /** 用户权限扩展 */
    const handleUserLicenseExpand = (record: any) => {
        const userId = record.id;
        setUserInfo({
            roleOrUserId: userId,
            itemName: record.userName
        });
        setModalUserLicenseEx(true)
    };

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '用户名',
            dataIndex: 'userName',
            sorter: true,
        },
        {
            title: '用户组',
            dataIndex: 'userGroup',
            sorter: true,
            render: (text: any) => (text ? text.userGroupName : '')
        },
        {
            title: '角色',
            dataIndex: 'roles_Name',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            render: (text: any) => (
                text ? text.brandName : ''
            )
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
        },
        {
            title: '管理员',
            dataIndex: 'isAdmin',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isAdmin', e, 'user')}
                />
            )
        },
        {
            title: '权限',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleUserLicense(record)}>权限</Button>
                </Space>
        },
        {
            title: '特殊',
            dataIndex: 'userLicenseExpand',
            key: 'userLicenseExpand',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={'权限（特殊）扩展'}>
                        <Button size={"small"} type="primary" onClick={() => handleUserLicenseExpand(record)}>特殊</Button>
                    </Tooltip>
                </Space>
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用户',
        apiId: 'user',
        columns,
        showStates: true,
        EditComponent: UserEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalUserLicense ? (<RoleOrUserLicense
                closeModal={onCloseModalUserLicense}
                info={userInfo}
                useType={'userLicense'} />)
                : null}
            {modalUserLicenseEx ? (<RoleOrUserLicenseEx
                closeModal={onCloseModalUserLicenseEx}
                useType={'userLicense'}
                info={userInfo}
            ></RoleOrUserLicenseEx>) : null}
        </>
    )
}

export default User;
