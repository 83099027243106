import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react';
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../../system/WebEditUniversalNewEx';
import getList from '../../../../utilsTsx/universal/getList';
import fetchApi from '../../../../utils/api/fetchApi';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const [wxAccountWxMiniAppData, setWxAccountWxMiniAppData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService, {
                apiId: "generalData",
                apiExtend: 'wxAccountList',
                apiData: {
                    slectType: 'wxMiniApp'
                }
            }).then(res => {
                if (res.success) {
                    setWxAccountWxMiniAppData(res.data);
                }
            })
        };
        fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'customerServiceOperator',
        serviceId: 'business'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '客服使用者'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                // onKeyPress={(e: any) => {
                //     if (e.key === 'Enter') {
                //         handleOk(e)
                //     }
                // }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id"
                                tooltip='如不输入id，则自动生成id'>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="名称"
                                name="csoName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="推送地址"
                                name="pushAddress"
                                rules={[{ required: true, message: '推送地址!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="微信小程序"
                                name="wxAccountId">
                                <Select
                                    showSearch
                                    placeholder="微信小程序"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(wxAccountWxMiniAppData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebEdit;