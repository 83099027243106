/**
 * 请求的类型
 * @type GET（SELECT）：从服务器查询，可以在服务器通过请求的参数区分查询的方式。
 * @type POST（CREATE）：在服务器新建一个资源，调用insert操作。
 * @type PUT（UPDATE）：在服务器更新资源，调用update操作。
 * @type DELETE（REMOVE）：从服务器删除资源，调用delete语句
 * @type HEAD：请求一个与GET请求的响应相同的响应，但没有响应体.
 * @type CONNECT ：建立一个到由目标资源标识的服务器的隧道。
 * @type OPTIONS ： 用于描述目标资源的通信选项。
 * @type TRACE ： 沿着到目标资源的路径执行一个消息环回测试。
 * @type PATCH ： 用于对资源应用部分修改。
 */
export declare type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'CONNECT'
    | 'OPTIONS' | 'TRACE' | 'PATCH';

/** ‌互联网协议版本枚举 */
declare enum protocolEnum {
    /** ipV4 */
    ipV4 = 'IPV4',
    /** ipV6 */
    ipV6 = 'IPV6',
};

/** ‌互联网协议版本详情 */
declare interface protocolDetails {
    [protocolEnum.ipV4]: {
        /** id */
        id: 'IPV4',
        /** 名称 */
        name?: '版本4（IPv4）'
    },
    [protocolEnum.ipV6]: {
        /** id */
        id: 'IPV6',
        /** 名称 */
        name?: '版本6（IPv6）'
    }
};

/** ‌互联网协议版本类型 */
export declare type protocolType = protocolDetails[protocolEnum];

/** 平台枚举 */
declare enum appPlatformEnum {
    /** windows */
    windows = 'WINDOWS',
    /** linux */
    linux = 'LINUX',
    /** android */
    android = 'ANDROID'
};

/** 平台详情 */
declare interface appPlatformDetails {
    [appPlatformEnum.windows]: {
        /** id */
        id: 'WINDOWS',
        /** 名称 */
        name?: '微软windows'
    },
    [appPlatformEnum.linux]: {
        /** id */
        id: 'LINUX',
        /** 名称 */
        name?: 'LINUX'
    },
    [appPlatformEnum.android]: {
        /** id */
        id: 'ANDROID',
        /** 名称 */
        name?: '安卓'
    }
};

/** 平台类型 */
export declare type appPlatformType = appPlatformDetails[appPlatformEnum];

/** app架构枚举 */
declare enum appArchEnum {
    /** x86 */
    x86 = 'X86',
    /** ipV6 */
    x64 = 'X64',
};

/** app架构详情 */
declare interface appArchDetails {
    [appArchEnum.x86]: {
        /** id */
        id: 'X86',
        /** 名称 */
        name?: '32位'
    },
    [appArchEnum.x64]: {
        /** id */
        id: 'X64',
        /** 名称 */
        name?: '64位'
    }
};

/** app架构类型 */
export declare type appArchType = appArchDetails[appArchEnum];

/** 排序方式类型，asc：升序；desc：降序； */
export declare type sortByType = 'ASC' | 'DESC';

/** Uuid短码长度类型 */
export declare type idLenghtType = 8 | 16 | 32;

/** 字母类型，UPPERCASE：大写，LOWERCASE：小写，ALL：所有 */
export declare type letterFixType = 'UPPERCASE' | 'LOWERCASE' | 'ALL';

/** dateTime数据类型 */
export declare type dateTimeType = 'TIMESTAMP' | 'DATETIME' | 'DATE' | 'TIME' | 'YEAR' | 'MONTH' | 'DAY' | 'HOUR' | 'MINUTE' | 'SECOND';

/** snowflakeId 前后缀数据类型 */
export declare type snowflakeIdFixType = "PREFIX" | "SUFFIX";

/** 编码类型 */
export declare type encodingType = 'GBK' | 'UTF8';

/** 对齐类型 */
export declare type alignType = 'LEFT' | 'RIGHT' | 'CENTER';

/** 位置类型 */
export declare type directionType = 'START' | 'END' | 'CENTER';

/** 设备型号枚举 */
declare enum deviceModelEnum {
    /** DP320PUW */
    DP320PUW = 'DP320PUW'
};

/** 设备型号详情 */
declare interface deviceModelDetails {
    [deviceModelEnum.DP320PUW]: {
        /** id */
        id: 'DP320PUW',
        /** 名称 */
        name?: 'DP320PUW热敏打印机',
        /** 品牌 */
        barnd?: '大通优联'
    }
};

/** 设备型号类型 */
export declare type deviceModelType = deviceModelDetails[deviceModelEnum];

/** 返回数据类型 */
export declare type rtnType = {
    /** 成功标志 */
    success: boolean,
    /** 数据 */
    data?: any,
    /** 错误代码 */
    errorCode?: number,
    /** 信息 */
    message?: string,
};

/**
 * 默认rtn 
 * @param options 选项 {
 * success: false,
 * data: undefined,
 * errorCode: undefined,
 * message: ''
 * }
 * @returns 
 */
export const defaultRtn = (options?: rtnType) => {
    if (typeof options === 'undefined') {
        options = {
            success: false
        };
    }
    const {
        success, data, errorCode, message
    } = options;
    const rtn: rtnType = {
        /** 成功标志 */
        success: (typeof success !== 'undefined') ? success : false,
        /** 数据 */
        data: (typeof data !== 'undefined') ? data : undefined,
        /** 错误代码 */
        errorCode: (typeof errorCode !== 'undefined') ? errorCode : undefined,
        /** 信息 */
        message: (typeof message !== 'undefined') ? message : ''
    }
    return rtn;
};
