import React, { ReactElement, useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import ComForm from '../comForm/comForm';
import WebEditUniversal from '../system/WebEditUniversalNewEx';
import { formInitDataType, initData } from '../typeValidation';
//布局
const layout = {
  // labelCol: { span: 8 },
  // wrapperCol: { span: 16 }, 
};

/** 
  * @param formData form数据结构
  * @param vis  控制弹窗的显示
  * @param  title 弹窗的标题
  * 
   **/
const Pop = (props: any): ReactElement => {
  let initData: initData = props.props
  const {
    formData,
    data,
    extendProps,
    form,
    vis,
    title
  } = initData
  const ref = useRef<any>(undefined);
  //本次form表单的展示内容
  const [formItem, setForItem] = useState<[]>([])
  //控制弹窗
  const [basicVisable, setBasicVisable] = useState<boolean>(vis)
  //控制出现的数据
  const [Count, setCount] = useState<number>(0)
  // 合并form检验的数据
  const [totalData, setTotalData] = useState<object>({})
  useEffect(() => {
    const fetch = async () => {
      //初始化
      if (formData) {
        formData.forEach((item: any) => {
          if (item.count === Count) {
            setForItem(item.data)
          }
        })
      }
    }
    fetch()
  }, [formData, Count])
  const onCancel = async () => {
    if (Count === 0) {
      ref.current.handleCancel()
    } else {
      if (Count > 0) {
        setCount(Count - 1)
      } else { setCount(Count) }
    }
  }
  const onOk = async () => {
    await form.validateFields().then((res: object) => {
      setTotalData({ ...totalData, ...res })
      if (Count >= formData.length - 1) {
        ref.current.handleOk()
      } else {
        setCount(Count + 1)
      }
    })
  }
  //传给comForm的数据
  let formInitData: formInitDataType = {
    form,
    formItem,
    onFinish: onOk
  }
  return (
    <>
      <WebEditUniversal ref={ref} props={extendProps} totalData={totalData as object} />
      <AntDraggableModal
        forceRender={true}
        title={(data.type === 'NEW' ? '新增' : `编辑`) + `${title ? title : ""}`}
        visible={basicVisable}
        okText={Count >= formData.length - 1 ? "完成" : "下一步"}
        cancelText={Count <= 0 ? "取消" : "上一步"}
        onOk={onOk}
        onCancel={onCancel}
        closable={false}
        maskClosable={false}
        width={700}
      >
        <ComForm props={formInitData}></ComForm>
      </AntDraggableModal>
    </>
  );
}
export default Pop