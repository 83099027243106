import { useEffect, useRef, useState } from 'react';
import WxSubscribeMessageV2UserEdit from './WxSubscribeMessageV2UserEdit';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import dayjs from 'dayjs';

/**
 * 组件 
 * @param props 
 * @returns 
 */
const Web = (props: any) => {
    const apiId = 'wxSubscribeMessageV2User';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;
    /** 更改一项数据 */
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '微信用户',
            dataIndex: 'wxUser',
            render: (text: any) => (text ? text.nickName : '')
        },
        {
            title: '微信',
            dataIndex: 'wxAccount_Name'
        },
        {
            title: '类型',
            dataIndex: 'wxSubscribeMessageV2Type',
            render: (text: any) => (text ? text.typeName : '')
        },
        {
            title: '动态更新令牌',
            dataIndex: 'notifyCode',
        },
        {
            title: '最后使用时间',
            dataIndex: 'lastTime',
            render: (val: dayjs.ManipulateType) => <span>{dayjs(val).format('YYYY-MM-DD HH:MM:ss')}</span>,
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId.concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '微信订阅信息V2用户',
        apiId,
        columns,
        showStates: true,
        EditComponent: WxSubscribeMessageV2UserEdit,
        serviceId: 'business',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Web;