import { useEffect, useRef, useState } from 'react';
import WebProductOrderEdit from './WebProductOrderEdit';
import WebUniversal, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import dayjs from 'dayjs';
import { Button, Select, Tooltip } from 'antd';
import WebOrderPayEdit from "./WebOrderPayEdit";
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import WebConsign from "./WebConsign";

/**
 * 组件 WebProductOrder 
 * @param props 
 * @returns 
 */
const WebProductOrder = (props: any) => {
    const apiId = 'webProductOrder';
    const [modalVisibleWebOrderPayEdit,
        setModalVisibleWebOrderPayEdit] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalVisibleWebConsign,
        setModalVisibleWebConsign] = useState(false);
    const [orderStatusData, setOrderStatusData] = useState<any>();
    const [selectOrderStatusIdData, setSelectOrderStatusIdData] = useState(100);
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'orderStatus',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setOrderStatusData(res.data);
                    }
                })
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    let sendData = {
        type: '',
        id: '',
        productOrderId: ''
        // sendPageData: {},

    }

    //打开[新增-编辑]框
    const openModal = (type: string, id: string, productOrderId: string) => {
        sendData.type = type;
        sendData.id = id;
        sendData.productOrderId = productOrderId;
        setModalData(sendData);
        setModalVisibleWebOrderPayEdit(true);
    }

    //编辑
    const handleOrderPayEdit = (recordData: any) => {
        let id = recordData.orderPayId;
        let productOrderId = recordData.id;
        openModal('EDIT', id, productOrderId);
    };

    //子组件回调函数，关闭modal
    const onCloseModalWebOrderPayEdit = (state: boolean) => {
        setModalVisibleWebOrderPayEdit(false)
    };

    const ref = useRef<any>(undefined);

    /** 查看不同状态的订单 */
    const orderStatusChange = (e: any) => {
        setSelectOrderStatusIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { orderStatusId: e }
        }
        ref.current.initData(currentData);
    };

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '微信用户',
            dataIndex: 'nickName',
            render: (text: any, record: any) =>
            (record.wxUser.nickName +
                (record.wxUser.mobile ? '(' + record.wxUser.mobile + ')' : ''))
        },
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (
                text ? text.shopName : ''
            )
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatus_Name',
        },
        {
            title: '支付单号',
            dataIndex: 'orderPayId',
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}>
                <Button size={"small"} type="primary" onClick={() => handleOrderPayEdit(record)}>
                    {(typeof text !== "undefined" && text !== null) ?
                        (text.length >= 8 ? '......' + text.substring(text.length - 8, text.length) : text)
                        : ''
                    }
                </Button>
            </Tooltip>
        },
        {
            title: '下单时间',
            dataIndex: 'createdAt',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {dayjs(record.sysDate.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '最后收货确认',
            dataIndex: 'lastReceiptDate',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {dayjs(record.lastReceiptDate).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 物流发货按钮
     * @param record 
     * @returns 
     */
    const consignButton = (record: any) => {
        return (<>
            {record.productStatusId === 1 ?
                <Tooltip placement="topLeft" title={'查看或修改物流信息'}>
                    <Button
                        type="dashed"
                        onClick={() => consigning(record, 'Edit')}>
                        {record.productStatus_Name}
                    </Button>
                </Tooltip> :
                record.productStatus_Name}

            {/* {record.productStatusId === 0 ?
                <Button
                    onClick={() => consigning(record, 'Add')}>
                    发货
                </Button> :
                ''} */}

        </>)
    };

    /**
     * 关闭发货窗口
     */
    const onCloseWebConsign = () => {
        setModalVisibleWebConsign(false);
    }

    /**
     * 发货或查看
     * @param record 
     * @param type Edit Or Add
     */
    const consigning = (record: any, type: string) => {
        const sendObj = {
            type,
            productOrderId: record.productOrderId,
            productOrderDetailId: record.id,
            logisticsOrderId: record.logisticsOrderId
        }
        setModalData(sendObj);
        setModalVisibleWebConsign(true);
    };

    /** 子表头内容 */
    const expandableColumnsHead = [
        {
            title: '名称',
            dataIndex: 'productName',
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={record.id}>{
                text
            }</Tooltip>
        },
        {
            title: '规格',
            dataIndex: 'productPriceSpec',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '价格',
            dataIndex: 'price',
        },
        {
            title: '成交价',
            dataIndex: 'purchasePrice',
        },
        {
            title: '数量',
            dataIndex: 'amount',
        },
        {
            title: '单位',
            dataIndex: 'productUnit',
        },
        {
            title: '金额',
            dataIndex: 'totalPurchasePrice',
        },
        {
            title: '产品状态',
            dataIndex: 'productStatus_Name',
            render: (text: any, record: any) => (<Tooltip placement="topLeft" title={record.productStatusId === 2 ? dayjs(record.receiveDate).format('YYYY-MM-DD HH:mm:ss') : ''}>{
                consignButton(record)
            }</Tooltip>
            )
        },
        {
            title: '',
            dataIndex: 'chargebackStatus_Name',
        },
        {
            title: '退换规则',
            dataIndex: 'productReturnRules_Name',
        },
        {
            title: '销售',
            dataIndex: 'sales',
            render: (text: any) => (
                text ? (
                    <Tooltip placement="topLeft" title={text.id}>{text.salesName}</Tooltip>
                ) : ''
            )
        },
    ];

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;

    //总结栏
    // let summaryRender = {
    //     sumDataName: 'sumPrice'
    // };
    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品',
        apiId,
        columns,
        showStates: true,
        showAdd: false,
        EditComponent: WebProductOrderEdit,
        sendPageData: { orderStatusId: selectOrderStatusIdData },
        // summaryRender,
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'productOrderDetails',
    };

    return (
        <>
            <Select
                showSearch
                defaultValue="所有订单"
                onChange={orderStatusChange}
                placeholder="订单状态"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(orderStatusData)}
            </Select>
            <text>   </text>
            <WebUniversal props={extendProps} ref={ref} />
            {modalVisibleWebOrderPayEdit ? (<WebOrderPayEdit closeModal={onCloseModalWebOrderPayEdit} data={modalData} />) : null}
            {modalVisibleWebConsign ? (<WebConsign onClose={onCloseWebConsign} data={modalData} />) : null}
        </>
    )
}

export default WebProductOrder;