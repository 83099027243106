import { Form, Input, Row, Col, Switch, InputNumber, Select, Tooltip, Divider, DatePicker } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import moment from 'moment';
import objectHandle from '../../../utils/baseLib/objectHandle';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebProductPriceEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    // const { productSpecId } = data.sendPageData;
    //产品单位
    const [productUnitData, setProductUnitData] = useState<any>();
    //是否推广促销
    const [isPromoteSales, setIsPromoteSales] = useState(false);
    //佣金模式
    const [commissionsModeData, setCommissionsModeData] = useState<any>();
    //产品退货规则
    const [productReturnRulesData, setProductReturnRulesData] = useState<any>();
    //发货仓库
    const [deliveryWarehouseData, setDeliveryWarehouseData] = useState<any>();
    //退货仓库
    const [returnWarehouseData, setReturnWarehouseData] = useState<any>();
    //初始发货仓库数据
    const [initialDeliveryWarehouseIds, setInitialDeliveryWarehouseIds] = useState<any>([]);
    const ref = useRef<any>(undefined);
    /** 后端数据defaultService */
    let defaultService: any = '';
    useEffect(() => {
        //ref 数据
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        ref.current.row.then(async (res: any) => {
            if (res.success === true) {
                setIsPromoteSales(res.data.isPromoteSales);
                //shopId
                const shopId = res.data.product.shopId;
                //设置初始发货仓库数据
                const deliveryWarehouseId = res.data.deliveryWarehouseId;
                let deliveryWarehouseRows;
                if (objectHandle.isEmpty(deliveryWarehouseId) === false) {
                    deliveryWarehouseRows = deliveryWarehouseId.split('@');
                }
                setInitialDeliveryWarehouseIds(deliveryWarehouseRows);
                form.setFieldsValue({
                    deliveryWarehouseIds: deliveryWarehouseRows
                })
                //获取发货仓库getList
                fetchApi.GET(defaultService,
                    {
                        apiId: 'webLogisticsWarehouse',
                        apiExtend: 'getList',
                        apiData: {
                            /** 仓库类型 收发货0-发货2 #字符不能用 */
                            LWHTypeIds: '0*2',
                            /** 店铺id */
                            shopId
                        }
                    }).then(res => {
                        if (res.success) {
                            setDeliveryWarehouseData(res.data);
                        }
                    });
                //获取退货仓库getList
                fetchApi.GET(defaultService,
                    {
                        apiId: 'webLogisticsWarehouse',
                        apiExtend: 'getList',
                        apiData: {
                            /** 仓库类型 收发货0-收货1 分隔符*，#字符不能用 */
                            LWHTypeIds: '0*1',
                            /** 店铺id */
                            shopId
                        }
                    }).then(res => {
                        if (res.success) {
                            setReturnWarehouseData(res.data);
                        }
                    });
            }
        })

        //同步获取数据 async - await
        const fetchData = async () => {
            //获取产品单位
            fetchApi.GET(defaultService,
                {
                    apiId: 'webProductUnit',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setProductUnitData(res.data);
                    }
                });
            //获取通用数据-佣金模式
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'commissionsMode',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setCommissionsModeData(res.data)
                    }
                });
            //获取通用数据-产品退货规则
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'productReturnRules',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setProductReturnRulesData(res.data)
                    }
                });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** 重置 */
    const commissionsModeIdHandleSelect = () => {
        //重置
        form.resetFields(['commissions']);
    };

    /**
     * 佣金校验
     * @param rule 
     * @param value 
     * @param callback 
     * @returns 
     */
    const validateCommissions = (rule: any, value: any, callback: any) => {
        /** 当前表单佣金模式 */
        const commissionsModeId = form.getFieldValue('commissionsModeId');
        /** 当前表单现价 */
        const price = form.getFieldValue('price');
        if (value < 0) {
            antMessage('error',
                '佣金不能输入负数',
                'error');
            return;
        }
        switch (commissionsModeId) {
            case 1:
                if (value > 1) {
                    antMessage('error',
                        '佣金模式按(成交价)百分比，佣金最大百分比不能超过[1]，即100%！',
                        '[按百分比]：如输入0.1，每件产品按(成交价)10%支付佣金');
                    return;
                }
                break;
            case 2:
                if (value > price) {
                    antMessage('error',
                        '佣金模式按固定，佣金最大值不能超过当前(现价)[' + price + ']！',
                        '[按固定]：如输入10，每件产品支付10元佣金');
                    return;
                }
                break;
            case 3:
                if (value > 1) {
                    antMessage('error',
                        '佣金模式按(现价)百分比，佣金最大百分比不能超过[1]，即100%！',
                        '[按百分比]：如输入0.1，每件产品按(现价)10%支付佣金');
                    return;
                }
                break;
        }
        callback();
    };

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webProductPrice',
        dateColumnName: ['promoteSalesStartDate',
            'promoteSalesEndDate'],
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '产品价格'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={4}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                name="product_Name">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="productPriceSpecs_Name">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="单位"
                                name="productUnitId"
                                rules={[{ required: true, message: '单位!' }]}>
                                <Select
                                    showSearch
                                    placeholder="单位"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(productUnitData, 'unitName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="原价"
                                name="originalPrice"
                                rules={[{ required: true, message: '原价!' }]}>
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="现价"
                                name="price"
                                rules={[{ required: true, message: '现价!' }]}>
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="库存"
                                name="inventory">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="总销量"
                                name="quantitySold">
                                <InputNumber
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="发货仓库"
                                name="deliveryWarehouseIds"
                                initialValue={initialDeliveryWarehouseIds}
                                rules={[{ required: true, message: '发货仓库!' }]}>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="发货仓库"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(deliveryWarehouseData, 'LWHName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="退货仓库"
                                name="returnWarehouseId"
                                rules={[{ required: true, message: '退货仓库!' }]}>
                                <Select
                                    showSearch
                                    placeholder="退货仓库"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(returnWarehouseData, 'LWHName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="快递费"
                                name="isExpressFee"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Divider />

                        <Col span={4}>
                            <Form.Item
                                label="推广促销"
                                name="isPromoteSales"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    onChange={(e) => setIsPromoteSales(Boolean(e))}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="推广开始日期"
                                name="promoteSalesStartDate"
                                initialValue={moment(new Date())}
                                rules={[{ required: isPromoteSales, message: '推广开始日期!' }]}>
                                <DatePicker
                                    disabled={!isPromoteSales}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="推广结束日期"
                                name="promoteSalesEndDate"
                                initialValue={moment(new Date())}
                                rules={[{ required: isPromoteSales, message: '推广结束日期!' }]}>
                                <DatePicker
                                    disabled={!isPromoteSales}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="佣金模式"
                                name="commissionsModeId"
                                rules={[{ required: isPromoteSales, message: '佣金模式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="佣金模式"
                                    optionFilterProp="children"
                                    disabled={!isPromoteSales}
                                    onSelect={() => commissionsModeIdHandleSelect()}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(commissionsModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Tooltip
                                placement="topLeft"
                                title={'佣金根据佣金模式计算，[按百分比]：如输入0.1，每件产品按成交价10%支付佣金；[按固定]：如输入10，每件产品支付10元佣金'}>
                                <Form.Item
                                    label="计算"
                                    name="commissions"
                                    rules={[
                                        { required: isPromoteSales, message: '佣金!' },
                                        {
                                            validator: validateCommissions
                                        }]}>
                                    <InputNumber
                                        disabled={!isPromoteSales}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="退(换)货规则"
                                name="productReturnRulesId">
                                <Select
                                    placeholder="退(换)货规则"
                                    optionFilterProp="children"
                                    onSelect={() => commissionsModeIdHandleSelect()}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(productReturnRulesData)}
                                </Select>
                            </Form.Item>
                        </Col>



                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebProductPriceEdit;