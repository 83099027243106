import WebCommissionsOrderHandlingEdit from './WebCommissionsOrderHandlingEdit';
import WebUniversal, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button, Space, Switch, Tooltip } from 'antd';
import { useEffect, useRef } from 'react';
import moment from 'moment';
import fetchApi from '../../../utils/api/fetchApi';

const WebCommissionsOrderHandling = (props: any) => {

    const apiId = 'webCommissionsOrderHandling';
    const ref = useRef<any>(undefined);
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        //同步获取数据 async - await
        // const fetchData = async () => {
        // };
        // fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    /**
     * 审核订单
     * @param id 
     * @param checkOK
     */
    const checkOrder = async (id: string, checkOK: boolean) => {
        fetchApi.PUT(defaultService,
            {
                apiId: 'webCommissionsOrderHandling',
                apiVariable: id,
                apiExtend: 'checkOrder',
                apiData: {
                    checkOK
                }
            }).then(res => {
                if (res.success) {
                    ref.current.initData();
                }
            })
    };

    /**
     * 结算订单
     * @param isSettlement 
     */
    const settlementOrder = async (id: string, isSettlement: boolean) => {
        fetchApi.PUT(defaultService,
            {
                apiId: 'webCommissionsOrderHandling',
                apiVariable: id,
                apiExtend: 'settlementOrder',
                apiData: {
                    isSettlement
                }
            }).then(res => {
                if (res.success) {
                    ref.current.initData();
                }
            })
    };


    //表头
    const columnsHead = [
        {
            title: '销售',
            dataIndex: 'sales',
            render: (text: any) => (
                text ? text.salesName : ''
            )
        },
        {
            title: '产品',
            dataIndex: 'productName',
        },
        {
            title: '规格',
            dataIndex: 'productPriceSpec',
        },
        {
            title: '数量',
            dataIndex: 'amount',
        },
        {
            title: '成交额',
            dataIndex: 'totalPurchasePrice',
        },
        {
            title: '下单时间',
            dataIndex: 'sysDate',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {moment(record.sysDate.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '收货时间',
            dataIndex: 'receiveDate',
            render: (text: any) =>
                <span>
                    {moment(text).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '订单状态',
            dataIndex: 'commissionsOrderStatus_Name',
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    /** 子表头内容 */
    const expandableColumnsHead = [
        {
            title: '佣金模式',
            dataIndex: 'commissionsMode_Name',
            render: (text: any) =>
                <Tooltip
                    placement="topLeft"
                    title={'佣金根据佣金模式计算，[按百分比]：如输入0.1，每件产品按成交价10%支付佣金；[按固定]：如输入10，每件产品支付10元佣金'}>
                    {(text)}
                </Tooltip>

        },
        {
            title: '计算',
            dataIndex: 'commissions',
        },
        {
            title: '佣金',
            dataIndex: 'commissionsAmount',
        },
        {
            title: '审核',
            dataIndex: 'isCheck',
            render: (text: any, record: any) => (
                <Switch checkedChildren="已审核" unCheckedChildren="未审核"
                    checked={text} key={record.id}
                />
            )
        },
    ];

    /** 子表头 */
    let expandableColumns;


    /**
     * 获取佣金订单可审核列表
     */
    const handleGetCheckList = () => {
        ref.current.setExpandableColumns(
            expandableColumnsHead
                .concat([{
                    title: '审核操作',
                    dataIndex: 'id',
                    render: (text: any) => (
                        <>
                            <Space>
                                <Button type='primary' onClick={() => checkOrder(text, true)}>通过</Button>
                                <Button onClick={() => checkOrder(text, false)}>不通过</Button>
                            </Space>
                        </>
                    )
                },])
        )
        const currentInitData: initDataType = {
            apiId,
            sendPageData: {
                queryStatus: 'getCheck'
            }
        }
        ref.current.initData(currentInitData);
    };

    /**
     * 获取佣金订单可结算列表
     */
    const handleGetSettlementList = () => {
        ref.current.setExpandableColumns(
            expandableColumnsHead
                .concat([{
                    title: '审核时间',
                    dataIndex: 'checkDate',
                    render: (text: any) =>
                        <span>
                            {moment(text).format('YYYY-MM-DD HH:mm:ss')}
                        </span>,
                }, {
                    title: '审核人',
                    dataIndex: 'checkId',
                }, {
                    title: '佣金结算',
                    dataIndex: 'id',
                    render: (text: any) => (
                        <>
                            <Space>
                                <Button type='primary' onClick={() => settlementOrder(text, true)}>结算</Button>
                                <Button onClick={() => settlementOrder(text, false)}>不结算</Button>
                            </Space>
                        </>
                    )
                },])
        )
        const currentInitData: initDataType = {
            apiId,
            sendPageData: {
                queryStatus: 'getSettlement'
            }
        }
        ref.current.initData(currentInitData);
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '佣金订单处理',
        apiId,
        columns,
        showAdd: false,
        showOperation: false,
        EditComponent: WebCommissionsOrderHandlingEdit,
        //子表格(当表格内容较多，可分两行展示)
        isExpandable: true,
        expandableColumns,
        expandableDataName: undefined
    };

    return (
        <>
            <Button onClick={() => handleGetCheckList()}>可审核佣金订单</Button><text> </text>
            <Button onClick={() => handleGetSettlementList()}>可结算佣金订单</Button><text> </text>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebCommissionsOrderHandling;
