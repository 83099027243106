import { useEffect, useRef, useState } from 'react';
import DevelopColumnEdit from './DevelopColumnEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import { Switch } from 'antd';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';

/**
 * 组件 DevelopColumn 
 * @param props 
 * @returns 
 */
const DevelopColumn = (props: any) => {
    const apiId = 'developColumn';
    const { data, onClose } = props;
    const handleClose = () => {
        onClose();
    }

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;

    const [webUniversalVisible, setWebUniversalVisible] = useState(true);

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'columnName',
        },
        {
            title: '数据表',
            dataIndex: 'developTable',
            render: (text: any) => (typeof text !== 'undefined' ?
                text.tableName : '')
        },
        {
            title: '数据类型',
            dataIndex: 'developColumnType_Name'
        },
        {
            title: '主键',
            dataIndex: 'isPrimary',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '空值',
            dataIndex: 'isNullValue',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '关联数据表',
            dataIndex: 'relations'
        },

    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '开发数据column',
        apiId,
        columns,
        showStates: true,
        EditComponent: DevelopColumnEdit,
        sendPageData: { developTableId: data.id },
    };

    return (
        <>
            <AntDraggableModal
                title={'开发数据column'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleClose}
                onCancel={handleClose}
                closable={false}
                maskClosable={false}
                width={1080}>
                {webUniversalVisible ? <WebUniversal props={extendProps} ref={ref}></WebUniversal> : ''}
            </AntDraggableModal>
        </>
    )
}

export default DevelopColumn;