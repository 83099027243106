import { Form, Input, Row, Col, Switch, Select, Button, Tooltip, Divider } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx';
import getList from "../universal/getList";
import fetchApi from '../../utils/api/fetchApi';
import moment from 'moment';
import DevelopApiTest from './DevelopApiTest';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};
const { TextArea } = Input;
const DevelopApiEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [developProjectsData, setDevelopProjectsData] = useState<any>();
    const [methodData, setMethodData] = useState<any>();
    const [apiExtendData, setApiExtendData] = useState<any>();
    const [apiExtendNewItem, setApiExtendNewItem] = useState<any>();
    const [apiExtendItemValue, setApiExtendItemValue] = useState<any>();
    const [updatedAt, setUpdatedAt] = useState<any>();
    const [modalTestData, setModalTestData] = useState({});
    const [modalTestVisible, setModalTestVisible] = useState(false);

    //打开test
    const openModalTest = (id: any) => {
        let sendData = {
            type: 'Edit',
            id
        }
        setModalTestData(sendData);
        setModalTestVisible(true);
    }

    //子组件回调函数，关闭modalTest
    const onCloseModal = (state: boolean) => {
        setModalTestVisible(false)
    }

    /**
     * 更改新增的apiExtend Item
     * @param e 
     */
    const onChangeApiExtendNewItem = (e: any) => {
        setApiExtendNewItem(e.target.value);
    };

    /** 增加aipExtend列表的item */
    const addItem = () => {
        /** 合并的items */
        const mergeItems = apiExtendData.concat(
            {
                id: apiExtendNewItem,
                name: apiExtendNewItem
            }
        )
        setApiExtendData(mergeItems);
        setApiExtendItemValue('');
    };

    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then((res: any) => {
                if (typeof res !== 'undefined') {
                    if (res.success === true) {
                        setUpdatedAt(res.data.sysDate.updatedAt);
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'developProjects',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDevelopProjectsData(res.data);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'method',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setMethodData(res.data);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'apiExtend',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setApiExtendData(res.data);
                    }
                })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'developApi',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '开发Api，更新时间：' +
                    moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                // onKeyPress={(e: any) => {
                //     if (e.key === 'Enter') {
                //         handleOk(e)
                //     }
                // }}
                >

                    <Row gutter={[8, 0]}>
                        <Col span={5}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="项目"
                                name="developProjectsId"
                                rules={[{ required: true, message: '项目!' }]}>
                                <Select
                                    showSearch
                                    placeholder="项目"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(developProjectsData, 'projectName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="名称"
                                name="apiName"
                                rules={[{ required: true, message: '名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                label="功能"
                                name="effect"
                                rules={[{ required: true, message: '功能!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="token"
                                name="isToken"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="apiId"
                                name="apiId"
                                rules={[{ required: true, message: 'apiId!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="apiVariable"
                                name="apiVariable">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="方法"
                                name="methodId"
                                rules={[{ required: true, message: '方法!' }]}>
                                <Select
                                    showSearch
                                    placeholder="方法"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(methodData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="apiExtend"
                                name="apiExtendId">
                                <Select
                                    showSearch
                                    placeholder="api扩展"
                                    optionFilterProp="children"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }}
                                                    value={apiExtendItemValue}
                                                    onChange={onChangeApiExtendNewItem} />
                                                <Button type='link'
                                                    onClick={addItem}>新增</Button>
                                            </div>
                                        </div>
                                    )}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(apiExtendData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={21}>
                            <Form.Item
                                label="apiData"
                                name="apiData">
                                <TextArea
                                    placeholder="apiData"
                                    rows={2}>
                                </TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item>
                                <Tooltip placement="topLeft" title={'对api接口测试'}>
                                    <Button onClick={() => { openModalTest(data.id) }}
                                    >测试</Button>
                                </Tooltip>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="用法"
                                name="usage">
                                <TextArea
                                    placeholder="用法"
                                    rows={10}>
                                </TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="说明"
                                name="explain">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="版本"
                                name="version">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
            {modalTestVisible ? (<DevelopApiTest closeModal={onCloseModal} data={modalTestData} />) : null}
        </>
    )
}

export default DevelopApiEdit;