import { Switch, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

/**
 * 组件 
 * @param props 
 * @returns 
 */
const SmsManage = (props: any) => {
    const apiId = 'smsManage';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '短信服务',
            dataIndex: 'smsService',
            render: (text: any) => (
                text ? text.serviceName : ''
            )
        },
        {
            title: '发送手机码',
            dataIndex: 'phones',
        },
        {
            title: '内容',
            dataIndex: 'content',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '来源',
            dataIndex: 'comeFrom',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '发送状态',
            dataIndex: 'isSend',
            render: (text: any, record: any) => (
                <Switch checkedChildren="成功" unCheckedChildren="失败"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '发送时间',
            dataIndex: 'createdAt',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {moment(record.sysDate.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '错误信息',
            dataIndex: 'errorInfo',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '短信管理',
        apiId,
        columns,
        showStates: false,
        showEdit: false,
        serviceId: 'business',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default SmsManage;