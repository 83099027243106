import WebGiftMemberCardTypeEdit from './WebGiftMemberCardTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';
import { useEffect, useRef } from 'react';

const WebGiftMemberCardType = (props: any) => {

    const apiId = 'webGiftMemberCardType';
    /** 更改一项数据 */
    let handleChangeOne: any;
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    useEffect(() => {
        /** ref.current 为空，因为只有在函数返回并呈现内容之后才设置ref。
        每当传递给useffect钩子的数组的内容值发生更改时，
        它都会触发useffect钩子。
        通过在数组中传递 handleChangeOne 并将记录 handleChangeOne 的回调传递到控制台，
        可以利用useffect钩子来完成任务。*/
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // initData = ref.current.initData;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '卡类型名称',
            dataIndex: 'typeName',
            sorter: true,
        },
        {
            title: '使用期限(月)',
            dataIndex: 'serviceLife'
        },
        {
            title: '类型',
            dataIndex: 'gmCardType_Name',
        },
        {
            title: '充值',
            dataIndex: 'isRecharge',
            render: (text: any, record: any) => (
                <Switch checkedChildren="允许" unCheckedChildren="禁止"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isRecharge', e, apiId)}
                />
            )
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '礼品会员卡类型',
        apiId,
        columns,
        showStates: true,
        EditComponent: WebGiftMemberCardTypeEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebGiftMemberCardType;
