import { useEffect, useRef, useState } from 'react';
import WxSubscribeMessageEdit from './WxSubscribeMessageEdit';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import { Switch, Tooltip } from 'antd';

/**
 * 组件 
 * @param props 
 * @returns 
 */
const Web = (props: any) => {
    const apiId = 'wxSubscribeMessage';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;
    /** 更改一项数据 */
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '订阅',
            dataIndex: 'wxSubscribeMessageUser',
            render: (text: any) => {
                const { wxSubscribeMessageType } = text;
                return wxSubscribeMessageType ? wxSubscribeMessageType.typeName : ''
            }
        },
        {
            title: '微信',
            dataIndex: 'wxSubscribeMessageUser',
            render: (text: any) => {
                const { wxUser } = text;
                return wxUser ? wxUser.nickName + '(' + wxUser.mobile + ')' : ''
            }
        },
        {
            title: '使用内容',
            dataIndex: 'useContent',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 30 ? text.substring(0, 30) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '信息',
            dataIndex: 'messageData',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 30 ? text.substring(0, 30) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '发送',
            dataIndex: 'isSend',
            render: (text: any, record: any) => (
                <Switch checkedChildren="成功" unCheckedChildren="失败"
                    checked={text} key={record.id}
                // onChange={(e) => handleChangeOne(record.id, 'isSend', e, apiId)}
                />
            )
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '微信订阅信息',
        apiId,
        columns,
        showStates: false,
        EditComponent: WxSubscribeMessageEdit,
        serviceId: 'business',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Web;