import { useRef } from 'react';
import UserGroupEdit from './UserGroupEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

const ShowPageComponent = (props: any) => {

    const ref = useRef<any>(undefined);
    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '用户组名',
            dataIndex: 'userGroupName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用户组',
        apiId: 'userGroup',
        columns,
        showStates: true,
        EditComponent: UserGroupEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default ShowPageComponent;
