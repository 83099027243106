import { useEffect, useRef, useState } from 'react';
import WebProductPriceEdit from './WebProductPriceEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Button, Popconfirm, Switch, Tooltip } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import WebProductPriceUploadFile from './WebProductPriceUploadFile';

//组件 WebProductPrice
const WebProductPrice = (props: any) => {
    const ref = useRef<any>(undefined);
    const { data, closeModal } = props;
    const [webUniversalVisible, setWebUniversalVisible] = useState(true);
    /** 后端数据defaultService */
    let defaultService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '产品',
            dataIndex: 'product',
            render: (text: any) =>
                (typeof text !== "undefined" && text !== null) ?
                    <Tooltip placement="topLeft" title={text.productName}>
                        {(text.productName.length >= 8 ? text.productName.substring(0, 8) + '......' : text.productName)}
                    </Tooltip>
                    : ''
        },
        {
            title: '单位',
            dataIndex: 'productUnit',
            render: (text: any) => (typeof text !== "undefined" && text !== null ?
                text.unitName :
                '')
        },
        {
            title: '规格',
            dataIndex: 'productPriceSpecs_Name',
            // render: (text: any) =>
            //     (typeof text !== "undefined" && text !== null) ?
            //         <Tooltip placement="topLeft" title={text}>
            //             {(text.length >= 8 ? text.substring(0, 8) + '......' : text)}
            //         </Tooltip>
            //         : ''
        },
        {
            title: '原价',
            dataIndex: 'originalPrice',
        },
        {
            title: '现价',
            dataIndex: 'price',
        },
        {
            title: '库存',
            dataIndex: 'inventory',
        },
        {
            title: '推广促销',
            dataIndex: 'isPromoteSales',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) =>
                //     typeof handleChangeOne !== "undefined" ?
                //         handleChangeOne(record.id, 'isUse', e, apiName) :
                //         undefined
                // }
                />
            )
        }
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(true);
        // ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        closeModal(false);
        // ref.current.handleCancel();
    };
    const handleCreatedProductPrice = async () => {
        setWebUniversalVisible(false);
        //send productId
        fetchApi.POST(defaultService,
            {
                apiId: 'webProductPrice',
                /** apiVaiable 是产品productId */
                apiVaiable: data.id,
                apiExtend: 'createdProductPrice'
            }).then(res => {
                console.log(res);
                setWebUniversalVisible(true);
            })
    }

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品价格',
        apiId: 'webProductPrice',
        columns,
        showStates: true,
        showUploadFile: true,
        showImage: true,
        EditComponent: WebProductPriceEdit,
        UploadFileComponent: WebProductPriceUploadFile,
        arrayFilesName: 'productPriceFiles',
        sendPageData: { productId: data.id },
        showDelete: false,
        showAdd: false,
    };

    return (
        <>
            <AntDraggableModal
                title={'产品价格'}
                open={true}
                okText='退出'
                cancelText='取消'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1080}>
                <Popconfirm title="根据产品规格设置，更新价格表，不存在规格价格将会删除，是否执行?"
                    onConfirm={() => handleCreatedProductPrice()}>
                    <Button type='primary'>更新产品价格表</Button>
                </Popconfirm>
                {webUniversalVisible ? <WebUniversal props={extendProps} ref={ref} ></WebUniversal> : ''}
            </AntDraggableModal>
        </>
    )
}

export default WebProductPrice;