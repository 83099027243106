import { Form, Input, Row, Col, Switch, Select, Slider, Divider } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx';
import getList from '../universal/getList';
import fetchApi from '../../utils/api/fetchApi';
import filesSystem from '../../config/filesSystem';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const FilesGroupEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [serviceData, setServiceData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    //小图
    const [smallImageDisabled, setSmallImageDisabled] = useState(false);
    const [smallImageRules, setSmallImageRules] = useState(false);
    const [imageFormat] = useState([{
        id: 'jpeg',
        name: 'JPEG图片'
    },
    {
        id: 'png',
        name: 'PNG图片'
    }]);
    //图片压缩
    const [compressImageDisabled, setCompressImageDisabled] = useState(false);
    const [compressImageRules, setCompressImageRules] = useState(false);
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then((res: any) => {
                if (res.success === true) {
                    const { isCreateSmallImage, isCompressImage } = res.data;
                    if (isCreateSmallImage === true) {
                        setSmallImageRules(true);
                    } else {
                        setSmallImageDisabled(true);
                    }
                    if (isCompressImage === true) {
                        setCompressImageRules(true);
                    } else {
                        setCompressImageDisabled(true);
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'filesService',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setServiceData(res.data);
                    }
                });
            const defaultFilesServer = await filesSystem.getDefaultServer();
            if (defaultFilesServer) {
                const { defaultServiceId } = defaultFilesServer;
                form.setFieldValue('serviceId', defaultServiceId);
            }

        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'filesGroup',
    };


    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '文件组'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={650}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            // handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={data.type === 'NEW' ? true : false} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="组名"
                                name="groupName"
                                rules={[{ required: true, message: '组名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="服务id"
                                name="serviceId"
                                rules={[{ required: true, message: '服务id!' }]}>
                                <Select
                                    showSearch
                                    placeholder="服务id"
                                    optionFilterProp="children"
                                    disabled={true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(serviceData, 'serviceName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="文件夹"
                                name="folder"
                                rules={[{ required: true, message: '文件夹!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="子目录"
                                name="subdirectory">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="子目录2"
                                name="secondSubdirectory">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="容量限制(MB)"
                                name="fileSizeLimit">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="日期目录"
                                name="isCreateDateDirectory"
                                tooltip='是否每天生成日期目录存放文件?'
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="原始文件名"
                                name="isUseOriginalFileName"
                                tooltip='是则使用原始文件名称，不随机生成文件名称'
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Divider />

                        <Col span={6}>
                            <Form.Item
                                label="压缩图片"
                                name="isCompressImage"
                                tooltip='如果文件是图片，则对图片进行压缩'
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    onChange={(e) => {
                                        setCompressImageDisabled(!e);
                                        setCompressImageRules(e);
                                    }}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="压缩图宽"
                                name="compressImageWidth"
                                rules={[{ required: compressImageRules, message: '输入压缩图宽!' }]}>
                                <Input disabled={compressImageDisabled} />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="压缩图高"
                                name="compressImageHeight"
                                rules={[{ required: compressImageRules, message: '输入压缩图高!' }]}>
                                <Input disabled={compressImageDisabled} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="压缩图格式"
                                name="compressImageFormat">
                                <Select
                                    showSearch
                                    placeholder="图片格式"
                                    optionFilterProp="children"
                                    disabled={compressImageDisabled}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(imageFormat)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="压缩图质量"
                                name="compressImageQuality"
                                rules={[{ required: compressImageRules, message: '使用缩图质量!' }]}>
                                <Slider disabled={compressImageDisabled} min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="生成小图"
                                name="isCreateSmallImage"
                                tooltip='如果文件是图片，则对生成方便浏览的小图'
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    onChange={(e) => {
                                        setSmallImageDisabled(!e);
                                        setSmallImageRules(e);
                                    }}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="小图宽"
                                name="smallImageWidth"
                                rules={[{ required: smallImageRules, message: '输入小图宽!' }]}>
                                <Input disabled={smallImageDisabled} />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="小图高"
                                name="smallImageHeight"
                                rules={[{ required: smallImageRules, message: '输入小图高!' }]}>
                                <Input disabled={smallImageDisabled} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="小图格式"
                                name="smallImageFormat"
                                rules={[{ required: smallImageRules, message: '选择小图格式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="图片格式"
                                    optionFilterProp="children"
                                    disabled={smallImageDisabled}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(imageFormat)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="小图质量"
                                name="smallImageQuality"
                                rules={[{ required: smallImageRules, message: '输入小图质量!' }]}>
                                <Slider disabled={smallImageDisabled} min={1} />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default FilesGroupEdit;