import { Form, Input, Switch } from 'antd';
import { useEffect, useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import systematic from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const LicenseExtendEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const { sendPageData } = data;
    const { licenseId } = sendPageData;

    const ref = useRef<any>(undefined);
    /** 后端数据wmService */
    const wmService = systematic.bmService;

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'licenseType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        console.log(res.data);

                        // setLicenseTypeData(res.data);
                    }
                });
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'licenseExtend',
    };
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '权限扩展'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="权限"
                        name="licenseId"
                        initialValue={data.type === 'NEW' ? licenseId : ''}>
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="权限扩展id"
                        name="exId"
                        rules={[{ required: true, message: '请输入权限扩展id!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="权限扩展名称"
                        name="exName"
                        rules={[{ required: true, message: '请输入权限扩展名称!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}

export default LicenseExtendEdit;