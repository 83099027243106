import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal from '../../system/WebEditUniversalNewEx';
import { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

export default function WebConversionCodeEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const [couponData, setCouponData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';
    useEffect(() => {
        if (ref.current) {
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'webCoupon',
                    apiExtend: "showList"
                }).then(res => {
                    console.log(res);

                    if (res.success) {

                        setCouponData(res.data);
                    }
                });
        };
        fetchData();
    }, []);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webConversionCode',
    };
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '兑换券'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="Id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="优惠券"
                                name="couponId"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="优惠券"
                                    optionFilterProp="children"

                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(couponData, 'couponName')}
                                </Select>
                            </Form.Item>
                        </Col>
                     

                      
                        <Col span={12}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input  />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}
