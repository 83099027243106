import { useEffect, useRef, useState } from 'react';
import WxPayRefundOrderEdit from './WxPayRefundOrderEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import fetchApi from '../../../utils/api/fetchApi';
import AntdNotification from '../../../utils/extend/AntdNotification';

const WebComponent = (props: any) => {
    const apiId = 'wxPayRefundOrder';
    const { closeModal, data } = props;
    const { payOrderId } = data;

    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    const [modalVisibleWxPayOrderEdit, setModalVisibleWxPayOrderEdit] = useState(false);
    const [modalData, setModalData] = useState({});
    /** 默认后台service */
    const [defaultService, setDefaultService] = useState<any>();
    /** 刷新 */
    const [initData, setInitData] = useState<any>();

    //子组件回调函数
    const onVisibleModalWxPayOrderEdit = (state: boolean) => {
        setModalVisibleWxPayOrderEdit(state);
    }

    useEffect(() => {
        if (ref.current) {
            setDefaultService(ref.current.defaultService);
            setInitData(() => ref.current.initData);
        }
        // const fetchData = async () => {
        // };
        // fetchData();
    }, []); //初始化数据

    /**
     * 执行微信支付退款
     * @param id 微信退款单id
     */
    const executeWxPayRefund = (id: string) => {
        fetchApi.PUT(defaultService, {
            apiId: 'wxPayRefundOrder',
            apiExtend: 'execute',
            apiVariable: id
        }).then(res => {
            if (res.success === true) {
                AntdNotification('success', '退款成功', 'success');
                initData();
            } else {
                AntdNotification('error', '退款失败', res.message);
            }
        })
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '支付订单',
            dataIndex: 'payOrderId',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '退款金额',
            dataIndex: 'refundAmount',
        },
        {
            title: '退款状态',
            dataIndex: 'refundStatus_Name',
        },
        {
            title: '退款',
            dataIndex: 'id',
            render: (text: any) => <Tooltip placement="topLeft" title={'执行退款'}>
                <Button onClick={() => executeWxPayRefund(text)}>退款</Button>
            </Tooltip>
        },
        {
            title: '成功',
            dataIndex: 'isRefund',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record['isRefund']}
                />
            )
        },
        {
            title: '退款id',
            dataIndex: 'wxPayRefundId',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '退款时间',
            dataIndex: 'refundTime',
            render: (val: dayjs.ManipulateType) => <span>{dayjs(val).format('YYYY-MM-DD HH:MM:ss')}</span>,
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '微信退款单',
        serviceId: 'pay',
        apiId,
        columns,
        showStates: true,
        showQuery: false,
        showAdd: true,
        // showLogger: {
        //     loggerSingleDisplay: true,
        //     logger: { id: 'wxPayRefundOrder' }
        // },
        showLogger: {
            loggerSingleDisplay: true,
            logger: { id: 'payLogger' }
        },
        sendPageData: { payOrderId },
        EditComponent: WxPayRefundOrderEdit
    };

    return (
        <>
            <AntDraggableModal
                title={'微信退款单'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={closeModal}
                closable={false}
                maskClosable={false}
                width={1080} >
                <WebUniversal props={extendProps} ref={ref} />
            </AntDraggableModal>
        </>
    )
}

export default WebComponent;