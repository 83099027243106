import { Button, Switch, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import WebConversionCodeEdit from './WebConversionCodeEdit';
import BatchConversionCode from './BatchConversionCode';


export default function WebConversionCode(props: any) {
    const ref = useRef<any>(undefined);
    let handleChangeOne: any;
    const apiId = "webConversionCode";
    const [batchConversionCodeModel,setBatchConversionCodeModel]=useState<boolean>(false)
    useEffect(() => {
        if (ref.current) {

            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        //同步获取数据 async - await
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化

    /**
     * 表头内容
     */
    const columnsHead = [

        {
            title: '优惠券名称',
            dataIndex: 'coupon',
            render: (text: any) => (text ? text.couponName : "")
        },
        {
            title: '兑换码',
            dataIndex: 'exchangeCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '发送',
            dataIndex: 'isSend',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isSend', e, apiId)}
                />
            )
        },
        {
            title: '兑换人',
            dataIndex: 'wxUser',
            render: (text: any) => (text ? text.nickName : "")
        },
        {
            title: '号码',
            dataIndex: 'wxUser',
            render: (text: any) => (text ? text.mobile : "")
        },
        {
            title: '备注',
            dataIndex: 'memo',
        },

    ];
    /**打开批量兑换 */
     const handleBatchConversionCode=()=>{
        setBatchConversionCodeModel(true)
     }

    /**关闭批量兑换 */
    const onCloseBatchConversionCode=(status:boolean)=>{
          setBatchConversionCodeModel(false)
          if(status){
           ref.current.initData()
              
          }
    }
    /**
    * 表头
    */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '兑换券',
        apiId,
        columns,
        showStates: true,
        EditComponent: WebConversionCodeEdit,
    };
    return (
        <>
            <Button onClick={handleBatchConversionCode} danger>批量生成兑换券</Button>
            <WebUniversal ref={ref} props={extendProps} />
            {batchConversionCodeModel?<BatchConversionCode closeModel={onCloseBatchConversionCode}></BatchConversionCode>:null}
        </>
    )
}
