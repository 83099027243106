import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx';
import getList from "../universal/getList";
import fetchApi from '../../utils/api/fetchApi';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const DevelopDatabaseEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [developProjectsData, setDevelopProjectsData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'developProjects',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDevelopProjectsData(res.data);
                    }
                })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiName: 'developDatabase',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '开发数据库'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                // onKeyPress={(e: any) => {
                //     if (e.key === 'Enter') {
                //         handleOk(e)
                //     }
                // }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id"
                                rules={[{ required: true, message: 'id!' }]}>
                                <Input disabled={data.type === 'NEW' ? false : true}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="名称"
                                name="databaseName"
                                rules={[{ required: true, message: '名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="项目"
                                name="developProjectsId"
                                rules={[{ required: true, message: '项目!' }]}>
                                <Select
                                    showSearch
                                    placeholder="项目"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(developProjectsData, 'projectName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={19}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default DevelopDatabaseEdit;